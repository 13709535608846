import React from "react";
import BaseComponent from "./../components/BaseComponent.js";
import ajax from "../utils/ajax";
import {ALL_LANGUAGES, ALL_TEST_TYPES, API_BASE_ADDRESS} from "../utils/constants";
import LoggedInUserContext from "./../contexts/LoggedInUserContext";

class ContactUsPage extends BaseComponent {

	state = {
		name: "",
		email: "",
		test_type: "",
		other_test_specified: "",
		other_test_input_showing: false,
		language: "",
		other_language_specified: "",
		other_language_input_showing: false,
		inquiry: "",
		form_class: "needs-validation",
		location: ""
	};

	saved_initial_state = {};

	sendEmail = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let {name, email, inquiry, test_type, other_test_specified, language, other_language_specified} = this.state;
		let emplid = null;

		let form = document.getElementById("contact-form");
		if (form.checkValidity() === false) {
			this.addToState({form_class: "was-validated"});
		} else {
			if (this.props.loggedInUser) {
				emplid = this.props.loggedInUser.emplid;
			} else {
				emplid = "Not specified (user was not logged in)";
			}


			let test_type_abbr = test_type.substr(0,test_type.indexOf(' '));
			if(other_test_specified.length > 0) {
				test_type_abbr = other_test_specified;
			}else if(test_type === 'LPE Screening') {
				test_type_abbr = 'Screening';
			}

		    const subject = language + " " + test_type_abbr + " Inquiry";
			const from = email;
			const body = name + " (" + email + ") submitted an inquiry:\n\n"
				+ "Test Type: " + test_type + " " + other_test_specified + "\n"
				+ "Language: " + language + " " + other_language_specified + "\n"
				+ "Student ID: " + emplid + "\n"
				+ "Message: " + inquiry + "\n";

			const params = {from, subject, body};

			ajax.post(API_BASE_ADDRESS + "/contact_us", params).then((result) => {
				if (result) {
					if (result.success) {
						this.resetState({success: true, old_name: this.state.name});
					} else {
						this.setState({success: false});
					}
				}
			});
		}
	};

	componentDidMount() {
		this.saved_initial_state = this.state;
		this.addToState(this.getUserDetails());
		if (this.props.match.params.location != null){
			this.addToState({location: this.props.match.params.location});
		}
	}

	resetState = (additional_state) => {
		this.addToState({
			...this.saved_initial_state,
			...this.getUserDetails(),
			...additional_state
		});
		if (this.props.match.params.location != null){
			this.addToState({location: this.props.match.params.location});
		}
	};

	getUserDetails = () => {
		if (this.props.loggedInUser != null) {
			return {
				name: this.props.loggedInUser.display_name,
				email: this.props.loggedInUser.user_id + "@umn.edu"
			};
		} else {
			return {
				name: "",
				email: ""
			};
		}

	};

	handleOtherLanguage(event) {
		if (event.target.value === "Other") {
			this.setState({other_language_input_showing: true});
		} else {
			this.setState({other_language_input_showing: false});
			this.setState({other_language_specified: ""});
		}
	}

	handleOtherTest(event) {
		if (event.target.value === "Other") {
			this.setState({other_test_input_showing: true});
		} else {
			this.setState({other_test_input_showing: false});
			this.setState({other_test_specified: ""});
		}
	}

	redirectLegacy = () => {
		if (this.state.location === "legacy"){
			return (
				<a className="btn btn-primary mt-3" role="button" href="https://langtest.umn.edu/">Return back to the LPE site</a>
			);
		}
		return null;
	};

	render() {
		const userLoggedIn = this.props.loggedInUser != null;
		const {other_language_input_showing} = this.state;
		const {other_test_input_showing} = this.state;

		return (
			<div className="row">
				<div className="col-12">
					<h1>Contact Us</h1>
					<p>
						The CLA Language Testing Program offers web-based services.
						If you have a question, please submit an online inquiry.
					</p>
					{!userLoggedIn && (
						<p>If you have a UMN account, please log in to prepopulate the form with your name and University email.</p>
					)}
					{this.state.success === true && (
						<div className="alert alert-success text-center" role="alert">
							<h2>Email sent</h2>
							Thank you {this.state.old_name}, your inquiry has been
							submitted to the CLA Language Testing Program. <br/>
							Please allow one business day for a response. <br/>
							{this.state.location && (
								this.redirectLegacy()
							)}
						</div>
					)}
					{this.state.success === false && (
						<div className="alert alert-danger text-center" role="alert">
							<h2>Email sending failed</h2>
							Please check your email address and try again
						</div>
					)}
				</div>
				<div className="col-12">
					<form className={this.state.form_class} id="contact-form" noValidate>
						<div className="form-group">
							<label htmlFor="name">Name</label>
							<input
								id="name"
								className="form-control"
								value={this.state.name}
								onChange={(e) => this.setState({name: e.target.value})}
								disabled={userLoggedIn}
								required
							/>
							<div className="invalid-feedback">
								Please enter your name
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="email-address">Email Address</label>
							<input
								id="email-address"
								className="form-control"
								value={this.state.email}
								onChange={(e) => this.setState({email: e.target.value})}
								disabled={userLoggedIn}
								required
							/>
							<div className="invalid-feedback">
								Please enter your email address
							</div>
						</div>


						<div className="form-group">
							<label htmlFor="test">Test</label>
							<select
								id="test"
								className="form-control"
								value={this.state.test_type}
								onChange={(e) => {
									this.setState({test_type: e.target.value});
									this.handleOtherTest(e);
								}}
								required
							>
								<option value="">Choose...</option>
								{
									ALL_TEST_TYPES.map((test) => {
										return (
											<option key={test} name={test}>{test}</option>
										);
									})
								}
								{
									<option key="Other" name="Other">Other</option>
								}
							</select>
							<div className="invalid-feedback">
								Please choose a test
							</div>
						</div>
						{other_test_input_showing ?
							<div className="form-group">
								<label htmlFor="other_test">If <em><strong> Other test </strong></em> please specify:</label>
								<input
									id="other_test"
									className="form-control"
									value={this.state.other_test_specified}
									onChange={(e) => this.setState({other_test_specified: e.target.value})}
									required
								/>
								<div className="invalid-feedback">
									Please specify the type of test you are inquiring about
								</div>
							</div>
							: null
						}
						<div className="form-group">
							<label htmlFor="language">Language</label>
							<select
								id="language"
								className="form-control"
								value={this.state.language}
								onChange={(e) => {
									this.setState({language: e.target.value});
									this.handleOtherLanguage(e);
								}}
								required
							>
								<option value="">Choose...</option>
								{
									ALL_LANGUAGES.map((lang) => {
										return (
											<option key={lang} name={lang}>{lang}</option>
										);
									})
								}
								{
									<option key="Other" name="Other">Other</option>
								}
							</select>
							<div className="invalid-feedback">
								Please choose a language
							</div>
						</div>
						{other_language_input_showing ?
							<div className="form-group">
								<label htmlFor="other_language">If <em><strong> Other language </strong></em> please specify:</label>
								<input
									id="other_language"
									className="form-control"
									value={this.state.other_language_specified}
									onChange={(e) => this.setState({other_language_specified: e.target.value})}
									required
								/>
								<div className="invalid-feedback">
									Please specify the language you are inquiring about
								</div>
							</div>
							: null
						}
						<div className="form-group">
							<label htmlFor="inquiry">Inquiry</label>
							<textarea
								id="inquiry"
								className="form-control"
								value={this.state.inquiry}
								onChange={(e) => this.setState({inquiry: e.target.value})}
								required
							/>
							<div className="invalid-feedback">
								Please enter your inquiry
							</div>
						</div>
						<button className="btn btn-primary" onClick={this.sendEmail} type="submit">Contact Us</button>
					</form>
				</div>
				<div className="col-12 mt-5">


					<p>
						All Testing Program services are provided remotely, and all staff are working remotely.
					</p>

					<p>
						<strong>Mailing address:</strong> CLA Language Testing Program<br/>
						110 Jones Hall<br/>
						27 Pleasant St. SE<br/>
						Minneapolis, MN 55455
					</p>
				</div>
			</div>
		);
	}

}

export default props => (
	<LoggedInUserContext.Consumer>
		{loggedInUser => (
			<ContactUsPage {...props} loggedInUser={loggedInUser}/>
		)}
	</LoggedInUserContext.Consumer>
);
