import React from "react";

export default class AssistantChooseLanguagePanel extends React.Component{
	render(){
		return(
			<div className="card-body">
				<h1>
					{"Choose a Language"}
				</h1>

				<div>
					<a className="btn btn-lg btn-primary mx-4"
						role="button"
						href="/assistant/run/span">
						Spanish
					</a>
					<a className="btn btn-lg btn-primary mx-4"
						role="button"
						href="/assistant/run/fren">
						French
					</a>
					<a className="btn btn-lg btn-primary mx-4"
						role="button"
						href="/assistant/run/ger">
						German
					</a>
				</div>
			</div>
		);
	}
}
