import React from "react";
import FAQ from "./FAQ";

export default class FAQAccordion extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="accordion" id={"accordion" + this.props.id}>
				{this.props.faqs.map((faq, i) =>
					<FAQ
						key={faq.id}
						index={i}
						collapsible={true}
						{...faq}
					/>
				)}
			</div>
		);
	}
}