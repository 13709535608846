import React from "react";
import BaseComponent from "./BaseComponent";
import SectionScorePanelList from "./SectionScorePanelList";
import LoadingIndicator from "./LoadingIndicator";
import ajax from "./../utils/ajax";
import {API_BASE_ADDRESS, EXAM_TYPES, GRADE_TYPES, USER_ROLE_TYPES} from "./../utils/constants";
import StudentPageCurrentStudentContext from "./../contexts/StudentPageCurrentStudentContext";
import invert from "lodash/invert";
import LoggedInUserContext from "../contexts/LoggedInUserContext";
import { CheckCircleIcon, ShareIcon } from "./Icons";

export default class StudentPageExamTabContent extends BaseComponent{

	state = {};

	componentDidMount(){
		this.fetchContents();
	}
	fetchContents(){
		const mostRecentExam = this.props.exams[0];

		if (mostRecentExam.language.type !== EXAM_TYPES.EPT){
			return;
		}

		return ajax.get(API_BASE_ADDRESS + "editable_content/snippet/" + mostRecentExam.language.language_id + "/" + mostRecentExam.results.combo_string)
			.then((contents)=>{
				this.addToState({contents});
			});
	}
	render(){
		const [mostRecentExam, ...previousExams] = this.props.exams;
		const testType = mostRecentExam.language.type;
		const hideSectionScores = this.props.hideSectionScores;

		return(
			<div>
				{testType !== EXAM_TYPES.EPT && (
					<StudentPageCurrentStudentContext.Consumer>
						{currentStudent => (
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={`https://langtest.umn.edu/studenthome/results?student_id=${currentStudent.uofm_id}&examid=${mostRecentExam.exam_id}`}
							>
								<ShareIcon/> View your <span className="font-weight-bold">{
									`${mostRecentExam.language.name} - ${invert(EXAM_TYPES)[testType]}`
								}</span> scores on the LPE website
							</a>
						)}
					</StudentPageCurrentStudentContext.Consumer>
				)}
				{testType == EXAM_TYPES.EPT && (
					<div>
						{this.state.contents && (
							<div>
								<h3 className="font-weight-bold">
									{
										GRADE_TYPES[mostRecentExam.results.overall] === GRADE_TYPES.F
											? "EPT Complete"
											: GRADE_TYPES[mostRecentExam.results.overall]
									}
									{
										((mostRecentExam.results.overall === "H") || (mostRecentExam.results.overall === "P")) && (
											<CheckCircleIcon className="text-success ml-2 small"/>
										)
									}
									<div className="small text-muted" dangerouslySetInnerHTML={{__html:this.state.contents.overall.html}}>
									</div>
								</h3>
								<div className="mt-3 mb-3" dangerouslySetInnerHTML={{__html:this.state.contents.details.html}}>
								</div>
							</div>
						)}
						{!this.state.contents && (
							<LoadingIndicator.Element/>
						)}
						<button
							className="btn btn-primary hide-after-click"
							type="button"
							data-toggle="collapse"
							data-target="#section-scores"
							aria-expanded={!hideSectionScores}
							aria-controls="section-scores">
							View Scores
						</button>
						<div id="section-scores" className={hideSectionScores? "collapse " : "" + "mt-3"}>
							<h4>Scores by Section</h4>
							<div className="mb-3">
								<SectionScorePanelList
									exam={mostRecentExam}
									onAfterEdit={this.props.refreshStudentPageContents}/>
							</div>
							<LoggedInUserContext.Consumer>
								{loggedInUser => (
									<React.Fragment>
										{loggedInUser && (loggedInUser.role === USER_ROLE_TYPES.admin) &&
										<a
											target="_blank"
											role="button"
											className="btn btn-primary"
											rel="noopener noreferrer"
											href={`https://langtest.umn.edu/admin/ept_admin/student_info/synchnow?exam=${mostRecentExam.exam_id}`}
										>
											<ShareIcon /> Sync
											scores using the legacy website
										</a>
										}
									</React.Fragment>
								)}
							</LoggedInUserContext.Consumer>
							{
								previousExams.length != 0 && (
									<div>
										<button
											className="btn btn-primary hide-after-click"
											type="button"
											data-toggle="collapse"
											data-target="#previous-attempts"
											aria-expanded="false"
											aria-controls="previous-attempts">
											View Previous Attempt{previousExams.length > 1? "s" : ""}
										</button>
										<div id="previous-attempts" className="collapse mt-3">
											<h4>Previous Attempt{previousExams.length > 1? "s" : ""}</h4>
											{
												previousExams.map((exam, key) => {
													return (
														<div className="card p-3 bg-light" key={key}>
															<SectionScorePanelList
																exam={exam}/>
														</div>
													);
												})
											}
										</div>
									</div>
								)
							}
						</div>
					</div>
				)}
			</div>
		);
	}
}
