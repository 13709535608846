import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment/moment";

import BaseComponent from "./BaseComponent";
import Announcement from "./Announcement";
import ajax from "../utils/ajax";
import LoadingIndicator from "./LoadingIndicator";
import {API_BASE_ADDRESS, DATE_TIME_FORMAT_LONG} from "../utils/constants";
import EditAnnouncementModal from "./modals/EditAnnouncementModal";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

export default class AnnouncementTableView extends BaseComponent {

	constructor() {
		super();

		this.state = {
			announcements: null,
		};

		this.renderAnnouncement = this.renderAnnouncement.bind(this);
		this.renderButtons = this.renderButtons.bind(this);
		this.renderStatus = this.renderStatus.bind(this);
		this.getAnnouncements = this.getAnnouncements.bind(this);
	}

	getAnnouncements() {
		ajax.get(API_BASE_ADDRESS + "announcements/index")
			.then((data)=>{
				this.addToState(data);
			});
	}

	deleteAnnouncement(id) {
		ajax.delete(API_BASE_ADDRESS + "announcements/delete", {id})
			.then((data)=>{
				if (data.deleted) {
					this.getAnnouncements();
				}
			});
	}

	componentDidMount() {
		this.getAnnouncements();
	}

	renderAnnouncement(announcement) {
		return (
			<Announcement key={announcement.id} index={announcement.id} {...announcement} />
		);
	}

	renderButtons(announcement) {
		return (
			<React.Fragment>
				<EditAnnouncementModal onSaveAnnouncement={this.getAnnouncements} announcement={announcement} />
				<button className="btn btn-primary btn-sm ml-sm-2" type="button" onClick={() => {
					if (window.confirm("Are you sure you want to delete this announcement?"))
						this.deleteAnnouncement(announcement.id);
				}}>
				Delete
				</button>
			</React.Fragment>
		);
	}

	renderStatus(announcement) {
		var now = moment();
		var active_start = moment(announcement.active_start);
		var active_end = moment(announcement.active_end);
		if (active_start <= now && active_end > now) {
			return (
				<div className="font-weight-bold">
					Active until { active_end.format(DATE_TIME_FORMAT_LONG) }.
				</div>
			);
		} else if (active_start > now && active_end > now) {
			return (
				<div>
					Scheduled to start on { active_start.format(DATE_TIME_FORMAT_LONG) } and end on { active_end.format(DATE_TIME_FORMAT_LONG) }.
				</div>
			);
		} else {
			return (
				<div className="font-weight-light font-italic">
					No longer active.
				</div>
			);
		}
	}

	generateColumns() {
		const columns = [
			{
				dataField: "id",
				text: "Id",
				hidden: true
			},
			{
				dataField: "announcements",
				isDummyField: true,
				text: "Content",
				formatter: (cellContent, row) => {
					return (
						this.renderAnnouncement(row)
					);
				}
			},
			{
				dataField: "status",
				isDummyField: true,
				text: "Status",
				formatter: (cellContent, row) => {
					return (
						this.renderStatus(row)
					);
				}
			},
			{
				dataField: "buttons",
				isDummyField: true,
				text: "Options",
				formatter: (cellContent, row) => {
					return (
						this.renderButtons(row)
					);
				}
			}
		];
		return columns;
	}

	render() {
		if (this.state.announcements == null) {
			return (
				<LoadingIndicator.Element/>
			);
		} else if (this.state.announcements.length === 0) {
			return (
				<div>
					<p>No announcements are currently available.</p>
					<EditAnnouncementModal onSaveAnnouncement={this.getAnnouncements} />
				</div>
			);
		} else {
			return (
				<div>
					<div className="mb-3">
						<EditAnnouncementModal onSaveAnnouncement={this.getAnnouncements} />
					</div>
					<BootstrapTable 
						boostrap4
						keyField="id"
						data={ this.state.announcements }
						columns={ this.generateColumns() }
						bordered={ false }
					/>
				</div>
			);
		}
	}
}
