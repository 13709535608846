import React from "react";
import {GRADE_TYPES, USER_ROLE_TYPES, FAILED} from "./../utils/constants";
import LoggedInUserContext from "../contexts/LoggedInUserContext";
import StudentPageCurrentStudentContext from "../contexts/StudentPageCurrentStudentContext";
import WritingOverrideCheckbox from "./WritingOverrideCheckbox";

export default class ReadingPlusListeningView extends React.Component {
	render() {
		var score = this.props.score;
		return (
			<div className="card mt-0 mb-3 mx-md-1 mx-lg-2">
				<div className="card-body py-md-1">
					<div className="card-text">
						Reading plus Listening: <strong>{GRADE_TYPES[score.status]}</strong> ({score.score} out
						of {score.max})
						<div className="small text-muted">Passing Score: {score.passing}</div>


						<LoggedInUserContext.Consumer>
							{loggedInUser => (
								<div>
									{
										(
											(loggedInUser.role === USER_ROLE_TYPES.admin) && (score.status === FAILED)

										)
										&& (
											<StudentPageCurrentStudentContext.Consumer>
												{studentPageCurrentStudent => (
													studentPageCurrentStudent && ( //dont show the ovverride option if there is not student page current student

														<WritingOverrideCheckbox
															exam={this.props.exam}
														/>

													)
												)}
											</StudentPageCurrentStudentContext.Consumer>
										)
									}
								</div>
							)}
						</LoggedInUserContext.Consumer>


					</div>
				</div>
			</div>
		);
	}
}
