import React from "react";
import SectionScorePanelList from "./../SectionScorePanelList";

export default class AssistantTemplate extends React.Component{
	render(){
		const outcome = this.props.outcome;
		const {editable_contents, editable_actions} = outcome;

		let exam;

		if (outcome.ept_score_data) {
			/*
				on this api edge, the backend sends us exam object separate of the results(ept_score_data).
				the SectionScorePanelList expects an exam object that has the exam results already included.
				so we'll manually merge those to together in a manner that the SectionScorePanelList will work with
			*/
			exam = Object.assign(
				{},
				outcome.exam_data,
				{results: outcome.ept_score_data}
			);
		}

		return(
			<span>
				{
					((this.props.showTopMessage && editable_contents.top) || exam) &&
					<div className="card-header">
						{
							this.props.showTopMessage && editable_contents.top && (
								<h1 dangerouslySetInnerHTML={{__html: editable_contents.top.html}}></h1>
							)
						}
						{
							exam && (
								<span>
									<h1>
										Your Results
									</h1>
									<SectionScorePanelList
										showReadingAndListening={false}
										exam={exam}/>
								</span>

							)
						}
					</div>
				}
				<div className="card-body">
					<h1>What&apos;s Next For You</h1>
					{editable_contents.overall && (
						<h2 className="my-4" dangerouslySetInnerHTML={ {__html:editable_contents.overall.html} }></h2>
					)}
					{editable_contents.details && (
						<div className="my-4" dangerouslySetInnerHTML={ {__html:editable_contents.details.html} }></div>
					)}
					<div>
						{
							Object.keys(editable_actions).map((key) => {
								return (
									<a
										className="btn btn-primary my-2 mx-2"
										key={key}
										role="button"
										href={editable_actions[key].action_url}
									>
										{editable_actions[key].action_text}
									</a>
								);
							})
						}
					</div>
				</div>
			</span>
		);
	}
}
