import React from "react";
import BaseComponent from "./BaseComponent";
import {DATE_FORMAT_SHORT, USER_ROLE_TYPES, GRADE_TYPES, EXAM_SECTIONS} from "./../utils/constants";
import moment from "moment";
import EditScoreModal from "./modals/EditScoreModal";
import LoggedInUserContext from "./../contexts/LoggedInUserContext";
import StudentPageCurrentStudentContext from "./../contexts/StudentPageCurrentStudentContext";
import PriorityScoringModal from "./modals/PriorityScoringModal";

export default class SectionScorePanel extends BaseComponent{
	render(){
		const score = this.props.score;
		const sectionName = this.props.name;

		if (sectionName === EXAM_SECTIONS.reading_plus_listening){
			return false;
		}

		const isIncompleteScore = score.status === "I";
		const isFail = GRADE_TYPES[score.status] === GRADE_TYPES.F;
		const isWriting = sectionName === EXAM_SECTIONS.writing;
		var {score:scoreValue, max} = score;
		var scoreHTML = "";
		var rubric = null;
		var cardClassName = "";
		var progressClassName = "bg-success";
		var progressWidth = 0;
		
		if (
			this.props.exam &&
			this.props.exam.language &&
			["Somali", "Hindi", "Urdu"].includes(this.props.exam.language.name) && 
			(sectionName === EXAM_SECTIONS.writing || sectionName === EXAM_SECTIONS.listening)) {
			cardClassName = "d-none";
		}
		else if (isIncompleteScore) {
			cardClassName = "text-muted";
		} else {
			progressWidth = scoreValue / max * 100;
			if (isFail) {
				progressClassName = "bg-danger";
			}
			if (isWriting) {
				rubric = "Grading Basis: Fail/Pass/High Pass";
				scoreHTML = "<strong>" + GRADE_TYPES[score.status] + "</strong>";
				progressWidth = 100; // Always show a full bar for writing.
			} else {
				rubric = "Passing Score: " + score.passing;
				if (score.highpass <= max) {
					rubric += ", High Passing Score: " + score.highpass;
				}
				scoreHTML = "<strong>" + GRADE_TYPES[score.status] + "</strong> (" + (score.score || 0) + " out of " + score.max + ")";
			}
		}

		return(
			<div className="card mx-md-1 mx-lg-2">
				<div className={"card-body " + cardClassName}>
					<ScoreEditButton score={this.props.score} sectionName={sectionName} onAfterEdit={this.props.onAfterEdit}/>
					<div className="card-title">
						<h5>
							{sectionName}
						</h5>
					</div>
					<div className="card-text">
						{isIncompleteScore ? (
							<div>
								{GRADE_TYPES[score.status]}
								{isWriting && this.props.isPendingWritingOnly && <PriorityScoringButton exam={this.props.exam} />}
							</div>
						) : (
							<div>
								<div dangerouslySetInnerHTML={{__html: scoreHTML}} />
								<div className="progress" style={{height: "0.25rem"}}>
									<div
										className={"progress-bar " + progressClassName}
										role="progressbar"
										style={{width: progressWidth + "%"}}
										aria-valuenow={scoreValue}
										aria-valuemin="0"
										aria-valuemax={max} />
								</div>
								<div className="small">
									{"taken on "}
									<strong>
										{moment(score.examdate, "MM/DD/YYYY").format(DATE_FORMAT_SHORT)}
									</strong>
								</div>
							</div>
						)}
					</div>
				</div>
				{rubric && (
					<div className="card-footer small text-muted">
						{rubric}
					</div>
				)}
			</div>
		);
	}
}

class ScoreEditButton extends BaseComponent {
	render() {
		if (this.props.score.status === "I") {
			return null;
		} else {
			return (
				<LoggedInUserContext.Consumer>
					{loggedInUser => (
						<div className="float-lg-right">
							{
								(
									loggedInUser.role === USER_ROLE_TYPES.admin
									|| loggedInUser.role === USER_ROLE_TYPES.staff
								)
								&& (
									<StudentPageCurrentStudentContext.Consumer>
										{studentPageCurrentStudent => (
											studentPageCurrentStudent && ( //dont show the edit button if there is not student page current student
												<EditScoreModal
													score={
														{
															score: this.props.score.score,
															max: this.props.score.max,
															student_score_id: this.props.score.student_score_id,
															type: this.props.sectionName,
														}
													}
													student={studentPageCurrentStudent}
													onAfterEdit={this.props.onAfterEdit}
												/>
											)
										)}
									</StudentPageCurrentStudentContext.Consumer>
								)
							}
						</div>
					)}
				</LoggedInUserContext.Consumer>
			);
		}
	}
}

class PriorityScoringButton extends BaseComponent {
	render() {
		return (
			<LoggedInUserContext.Consumer>
				{(loggedInUser) => (
					(loggedInUser.role == USER_ROLE_TYPES.admin || loggedInUser.role == USER_ROLE_TYPES.advisor)
					&& (
						<StudentPageCurrentStudentContext.Consumer>
							{(student) => {
								return (
									<div className="mt-3">
										<PriorityScoringModal
											student={student}
											exam={this.props.exam}/>
									</div>
								);
							}}
						</StudentPageCurrentStudentContext.Consumer>
					)
				)}
			</LoggedInUserContext.Consumer>
		);
	}
}