import React from "react";
import {NavLink as DOMNavLink} from "react-router-dom";
import LoggedInUserContext from "./../contexts/LoggedInUserContext";
import LoggedInUserNav from "./LoggedInUserNav";

import { Collapse, Container, Navbar, Nav, NavItem, NavLink } from "reactstrap";

import {USER_ROLE_TYPES} from "./../utils/constants";
import { BarsIcon } from "./Icons";

export default class NavigationBarForAdmin extends React.Component{
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	render() {
		const activeClassName = "active";
		return (
			<Navbar light expand="md" className="langtest-nav langtest-nav-admin border-bottom">
				<Container className="px-sm-3">
					<button className="d-md-none btn btn-sm btn-primary" onClick={this.toggle}>
						<BarsIcon /> Admin Menu
					</button>
					<LoggedInUserContext.Consumer>
						{ loggedInUser =>(
							<React.Fragment>
								<Collapse isOpen={this.state.isOpen} navbar>
									<Nav navbar>
										{loggedInUser && loggedInUser.role == USER_ROLE_TYPES.student && (
											<NavItem>
												<NavLink tag={DOMNavLink} to="/my_scores" activeClassName={activeClassName}>
													Your Scores
												</NavLink>
											</NavItem>
										)}
										{loggedInUser && (loggedInUser.role == USER_ROLE_TYPES.admin ||
											loggedInUser.role == USER_ROLE_TYPES.staff ||
											loggedInUser.role == USER_ROLE_TYPES.advisor)  && (
											<NavItem>
												<NavLink tag={DOMNavLink} to="/admin/students" activeClassName={activeClassName}>
													Student Search
												</NavLink>
											</NavItem>
										)}
										{loggedInUser && (loggedInUser.role == USER_ROLE_TYPES.admin ||
											loggedInUser.role == USER_ROLE_TYPES.staff) && (
											<React.Fragment>
												<NavItem>
													<NavLink tag={DOMNavLink} to="/admin/reports" activeClassName={activeClassName}>
														Reports
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink tag={DOMNavLink} to="/admin/announcements" activeClassName={activeClassName}>
														Announcements
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink tag={DOMNavLink} to="/admin/content/index" activeClassName={activeClassName}>
														Editable Content
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink tag={DOMNavLink} to="/admin/faq" activeClassName={activeClassName}>
														Edit FAQs
													</NavLink>
												</NavItem>
											</React.Fragment>
										)}
										<NavItem>
											<NavLink tag={DOMNavLink} to="/home" activeClassName={activeClassName}>
												Public Home
											</NavLink>
										</NavItem>
									</Nav>
								</Collapse>
								<LoggedInUserNav user={loggedInUser}/>
							</React.Fragment>
						)}
					</LoggedInUserContext.Consumer>
				</Container>
			</Navbar>
		);
	}
}
