import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import RichTextEditor from "react-rte";

import LoadingIndicator from "./../LoadingIndicator";
import ajax from "../../utils/ajax";
import {API_BASE_ADDRESS, FAQ_CATEGORIES, RICH_TEXT_TOOLBAR} from "../../utils/constants";

export default class EditFAQModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			faq: null,
			rte: null,
			pageTitle: "Loading...",
			toggleButtonText: ""
		};

		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	componentDidMount() {
		let newState;
		if (this.props.faq != null) {
			newState = {
				faq: this.props.faq,
				rte: {
					answer: RichTextEditor.createValueFromString(
						this.props.faq.answer, "html")
				},
				pageTitle: "Edit FAQ",
				toggleButtonText: "Edit"
			};
		} else {
			newState = {
				faq: {
					question: "",
					answer: "",
					category: "",
					order: ""
				},
				rte: {
					answer: RichTextEditor.createEmptyValue()
				},
				pageTitle: "Add new FAQ",
				toggleButtonText: "Add new FAQ"
			};
		}
		this.setState(newState);
	}

	saveFAQ = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let form = document.getElementById("faq-form");
		if (form.checkValidity() === false) {
			this.setState({form_class: "was-validated"});
		} else {
			if (this.state.faq.id) {
				ajax.put(API_BASE_ADDRESS + "faqs/update", this.state.faq)
					.then((data) => {
						if (data.updated) {
							this.props.onSaveFAQ();
							this.toggle();
						}
					});
			} else {
				ajax.post(API_BASE_ADDRESS + "faqs/add", this.state.faq)
					.then((data) => {
						if (data.created) {
							this.props.onSaveFAQ();
							this.toggle();
						}
					});
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<button
					className="btn btn-primary btn-sm"
					type="button"
					onClick={this.toggle}>{this.state.toggleButtonText}
				</button>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					{this.state.faq == null? (
						<React.Fragment>
							<ModalHeader toggle={this.toggle}>
								Loading, please wait...
							</ModalHeader>
							<ModalBody>
								<LoadingIndicator.Element/>
							</ModalBody>
						</React.Fragment>
					) : (
						<React.Fragment>
							<ModalHeader toggle={this.toggle}>{this.state.pageTitle}</ModalHeader>
							<ModalBody>
								<form id="faq-form" className={this.state.form_class} noValidate>
									<div className="form-group">
										<label htmlFor="question">Question</label>
										<input
											className="form-control"
											id="question"
											type="text"
											value={this.state.faq.question}
											onChange={e => {
												const faq = this.state.faq;
												faq.question = e.target.value;
												this.setState(faq);
											}}
											required
										/>
									</div>
									<div className="form-group">
										<label htmlFor="answer">Answer</label>
										<RichTextEditor
											id="answer"
											value={this.state.rte.answer}
											toolbarConfig={RICH_TEXT_TOOLBAR}
											onChange={e => {
												const rte = this.state.rte;
												rte.answer = e;
												this.setState(rte);
											}}
											onBlur={() => {
												const faq = this.state.faq;
												faq.answer =
								this.state.rte.answer.toString("html").replace(/<a /g, '<a target="_blank" ');
												this.setState(faq);
											}}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="category">Category</label>
										<select
											className="form-control"
											id="category"
											value={this.state.faq.category}
											onChange={e => {
												const faq = this.state.faq;
												faq.category = e.target.value;
												this.setState(faq);
											}}
											required
										>
											<option value="">--choose</option>
											{
												Object.keys(FAQ_CATEGORIES).map((category) => {
													return (
														<option key={category} name={category} value={category}>{FAQ_CATEGORIES[category]}</option>
													);
												})
											}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="order">Order</label>
										<select
											className="form-control"
											id="order"
											value={this.state.faq.order}
											onChange={e => {
												const faq = this.state.faq;
												faq.order = e.target.value;
												this.setState(faq);
											}}
										>
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
											<option>5</option>
											<option>6</option>
											<option>7</option>
											<option>8</option>
											<option>9</option>
											<option>10</option>
										</select>
									</div>
								</form>
							</ModalBody>
							<ModalFooter>
								<button
									className="btn btn-primary ml-sm-2"
									type="button"
									key={"save_" + this.state.faq.id}
									onClick={this.saveFAQ}
								>
									Save changes
								</button>
								<button
									className="btn btn-outline-secondary ml-sm-2"
									type="button"
									key={"cancel_" + this.state.faq.id}
									onClick={this.toggle}
								>
									Cancel
								</button>
							</ModalFooter>
						</React.Fragment>
					)}
				</Modal>
			</React.Fragment>
		);
	}
}

EditFAQModal.defaultProps = {
	onSaveFAQ: () => {
		return;
	}
};