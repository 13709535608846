import React from "react";
import DateRangePicker from "./elements/DateRangePicker";
import LoadingIndicator from "./../components/LoadingIndicator";
import ajax from "./../utils/ajax";
import queryString from "query-string";
import moment from "moment";
import { Button, ButtonGroup } from "reactstrap";
import {API_BASE_ADDRESS, GRADE_TYPES} from "./../utils/constants";
import omit from "lodash/omit";

export default class GenerateReportPanel extends React.Component {

	state = {
		sessions: [],
		languages: [],
		useQuickReport: false,
		useSession: false,
		useDateRange: false,
		resetDatePickerCounter: (new Date).getTime(),
		hideEmailOptions: true,
		selectedScoreType: "all",
		selectedQuickReport: "none",
		selectedOrderBy: "last_name",
		selectedLanguage: "all",
		selectedSession: "na",
		selectedOutputOption: "",
		emailTo: "",
		emailCc: "",
		emailSubject: "LangTest Custom Report Results",
		emailSubjectCustomized: false,
		emailBody: "",
		form_class: "needs-validation",
		loadingSessionData: true,
		loadingLanguageData: true,
		invalidDateFeedback: ""
	};

	changeOutputOption = (outputOption) => {
		if (outputOption == "display") {
			// if "display" has been clicked, generate the report as soon as the state has been updated.
			this.setState({form_class: "needs-validation", selectedOutputOption: outputOption, hideEmailOptions: true}, this.generateCustomReport);
		} else {
			this.setState({form_class: "needs-validation", selectedOutputOption: outputOption, hideEmailOptions: false});
		}
	};

	isValidDate = (date) => {
		let momentDate = moment(date, "MM-DD-YYYY", true);
		return momentDate.isValid();
	};

	updateScoreType = (selectedScoreType) => {
		this.setState({
			selectedScoreType: selectedScoreType
		}, this.updateEmailSubject);
	};

	updateOrderBy = (event) => {
		let selectedOrderBy = event.target.value;
		this.setState({
			selectedOrderBy: selectedOrderBy
		}, this.updateEmailSubject);
	};

	updateLanguage = (event) => {
		let selectedLanguage = event.target.value;
		this.setState({
			selectedLanguage: selectedLanguage
		}, this.updateEmailSubject);
	};

	updateQuickReportDates = (selectedQuickReport) => {
		let newState = {};

		if (selectedQuickReport == "none") {
			newState = {
				selectedQuickReport: selectedQuickReport,
				selectedStartMoment: null,
				selectedEndMoment: null,
				useQuickReport: false,
				invalidDateFeedback: ""
			};
		} else {
			let newStartMoment = QUICK_REPORT_TYPES[selectedQuickReport].start;
			let newEndMoment = QUICK_REPORT_TYPES[selectedQuickReport].end;
			newState = {
				selectedQuickReport: selectedQuickReport,
				selectedStartMoment: newStartMoment,
				selectedEndMoment: newEndMoment,
				useQuickReport: true,
				useSession: false,
				selectedSession: "na",
				useDateRange: false,
				invalidDateFeedback: ""
			};
		}
		newState.resetDatePickerCounter = (new Date).getTime();
		this.setState(newState, this.updateEmailSubject);
	};

	setUseDateRange = (selectedStartMoment, selectedEndMoment) => {
		let newState = {
			useQuickReport: false,
			selectedQuickReport: "none",
			useSession: false,
			selectedSession: "na",
			useDateRange: true,
			invalidDateFeedback: ""
		};
		if (typeof selectedStartMoment !== "undefined") {
			newState.selectedStartMoment = selectedStartMoment;
		}
		if (typeof selectedEndMoment !== "undefined") {
			newState.selectedEndMoment = selectedEndMoment;
		}
		this.setState(newState, this.updateEmailSubject);
	};

	updateSession = (event) => {
		let newState = {};
		let selectedSession = event.target.value;
		if (selectedSession === "na") {
			newState = {useSession: false, selectedSession: "na"};
		} else {
			newState = {
				resetDatePickerCounter: (new Date).getTime(),
				selectedQuickReport: "none",
				selectedStartMoment: null,
				selectedEndMoment: null,
				selectedSession: selectedSession,
				useQuickReport: false,
				useSession: true,
				useDateRange: false,
				invalidDateFeedback: ""
			};
		}
		this.setState(newState, this.updateEmailSubject);
	};

	updateEmailSubject = () => {
		if (!this.state.emailSubjectCustomized) {
			let newEmailSubject = "EPT Report, ";
			if (this.state.selectedLanguage == "all") {
				newEmailSubject += "All Languages, ";
			} else {
				let lang = this.state.languages.filter((language) =>
					this.state.selectedLanguage == language.language_id);
				newEmailSubject += lang[0].name + ", ";
			}
			if (this.state.selectedScoreType == "all") {
				newEmailSubject += "All Score Types";
			} else {
				newEmailSubject += GRADE_TYPES[this.state.selectedScoreType];
			}
			if (this.isValidDate(this.state.selectedStartMoment) && this.isValidDate(this.state.selectedEndMoment)) {
				newEmailSubject += ", " + this.state.selectedStartMoment.format("MM/DD/YYYY") + " to " + this.state.selectedEndMoment.format("MM/DD/YYYY");
			} else if (this.state.selectedSession) {
				if (this.state.selectedSession != "na") {
					let sess = this.state.sessions.filter((session) =>
						this.state.selectedSession == session.test_session_id);
					newEmailSubject += ", " + sess[0].full_name;
				}
			}
			this.setState({emailSubject: newEmailSubject});
		}
	};

	generateCustomReport = () => {
		let params = {};
		let invalidDateFeedback = "";

		if (this.state.useSession) {
			params.session_id = this.state.selectedSession;
		} else if (this.state.useDateRange) {
			let momentDateStart = this.state.selectedStartMoment;
			let momentDateEnd = this.state.selectedEndMoment;
			let now = moment();
			if (!this.isValidDate(momentDateStart)) {
				invalidDateFeedback = "Please enter a valid start date.";
			} else if (!this.isValidDate(momentDateEnd)) {
				invalidDateFeedback = "Please enter a valid end date.";
			} else if (momentDateEnd.isBefore(momentDateStart)) {
				invalidDateFeedback = "Please enter an end date that occurs after the start date.";
			} else if (momentDateStart.isAfter(now)) {
				invalidDateFeedback = "Please enter a start date that is not in the future.";
			} else if (momentDateEnd.isAfter(now)) {
				invalidDateFeedback = "Please enter an end date that is not in the future.";
			} else {
				params.from_date = this.state.selectedStartMoment.format();
				params.to_date = this.state.selectedEndMoment.format();
			}
		} else if (this.state.useQuickReport) {
			params.from_date = this.state.selectedStartMoment.format();
			params.to_date = this.state.selectedEndMoment.format();
		} else {
			invalidDateFeedback = "Please select a quick date, enter valid start and end dates (MM/DD/YYYY), or choose a session.";
		}

		let form = document.getElementById("report-form");
		if (invalidDateFeedback !== "" || form.checkValidity() === false) {
			this.setState({form_class: "was-validated", invalidDateFeedback: invalidDateFeedback});
		} else {
			if (this.state.selectedLanguage != "all") {
				params.language_id = this.state.selectedLanguage;
			}

			if (this.state.selectedScoreType) {
				params.score_type = this.state.selectedScoreType;
			}

			if (this.state.selectedOrderBy) {
				params.order_by = this.state.selectedOrderBy;
			}

			let baseReportAddress;
			if (this.state.selectedOutputOption == "email") {
				baseReportAddress = API_BASE_ADDRESS + "reports/ept/custom_email?";
				params.email_to = this.state.emailTo;
				params.email_cc = this.state.emailCc;
				params.email_subject = this.state.emailSubject;
				params.email_body = this.state.emailBody;
			} else {
				baseReportAddress = API_BASE_ADDRESS + "reports/ept/custom?";
			}
			this.props.onShouldGenerateNewReport(baseReportAddress + queryString.stringify(params));
		}
	};

	loadSessions() {
		const baseReportAddress = API_BASE_ADDRESS + "/test_sessions";
		return ajax.get(baseReportAddress).then((sessions) => {
			this.setState({sessions, loadingSessionData: false});
		});
	}

	loadLanguages() {
		const baseReportAddress = API_BASE_ADDRESS + "/languages";
		return ajax.get(baseReportAddress).then((languages) => {
			this.setState({languages, loadingLanguageData: false});
		});
	}

	componentDidMount() {
		this.loadSessions();
		this.loadLanguages();
	}

	render() {
		return (
			<LoadingIndicator.Wrapper show={this.state.loadingSessionData || this.state.loadingLanguageData}>
				<div className="border bg-white p-3">
					<form className={this.state.form_class + " mt-3"} id="report-form" noValidate>
						<fieldset>
							<legend>Date options <em className="text-muted">(choose one)</em></legend>
							<div className="form-row">
								<div className="col">
									<div className="mb-2">Quick Date</div>
									<ButtonGroup className={"langtest-report-toggle"}>
										{Object.keys(QUICK_REPORT_TYPES).map(
											(_key, index) => {
												return (
													<Button key={index} id={"quick_" + _key} color="primary" onClick={() => this.updateQuickReportDates(_key)} active={this.state.selectedQuickReport === _key}>{QUICK_REPORT_TYPES[_key].name}</Button>
												);
											}
										)
										}
									</ButtonGroup>
								</div>
								<div className="col-md-4 col-lg">
									<div className="mb-2">Date Range</div>
									<DateRangePicker
										inputClassName="custom-validated"
										reset={this.state.resetDatePickerCounter}
										onChangeStart={(selectedStartMoment) => {
											this.setUseDateRange(selectedStartMoment, undefined);
										}}
										onChangeEnd={(selectedEndMoment) => {
											this.setUseDateRange(undefined, selectedEndMoment);
										}}
									/>
								</div>
								<div className="col">
									<div className="form-group form-group-inline mr-3" role="group">
										<label htmlFor="session">Session</label>
										<select id="session"
											className="form-control custom-validated"
											value={this.state.selectedSession}
											onChange={(selectedSession) => {
												this.updateSession(selectedSession);
											}}
										>
											<option value="na">N/A
											</option>
											{this.state.sessions.map(
												(session, index) => {
													return (
														<option key={index}
															value={session.test_session_id}>{session.full_name}</option>
													);
												}
											)}
										</select>
									</div>
								</div>
							</div>
							{this.state.invalidDateFeedback != "" && (
								<div className={"invalid-feedback"} style={{"display": "block"}}>
									{ this.state.invalidDateFeedback }
								</div>
							)}
						</fieldset>

						<fieldset>
							<legend>Other options <em className="text-muted">(choose one of each)</em></legend>
							<div className="form-row">
								<div className="col">
									<div className="mb-2">Score Type</div>
									<ButtonGroup className="langtest-report-toggle">
										<Button key="-1" color="primary" onClick={() => this.updateScoreType("all")} active={this.state.selectedScoreType === "all"}>All</Button>
										{Object.keys(omit(GRADE_TYPES, "N")).map(
											(_key, index) => {
												return (
													<Button key={index} color="primary" onClick={() => this.updateScoreType(_key)} active={this.state.selectedScoreType === _key}>{GRADE_TYPES[_key]}</Button>
												);
											}
										)}
									</ButtonGroup>
								</div>

								<div className="col">
									<div className="form-group form-group-inline mr-3" role="group">
										<label htmlFor="language">Language</label>
										<select id="language"
											className="form-control"
											value={this.state.selectedLanguage.value}
											onChange={(selectedLanguage) => {
												this.updateLanguage(selectedLanguage);
											}}>
											<option value='all'>All Languages</option>
											{this.state.languages.map(
												(language, index) => {
													return (
														<option key={index} value={language.language_id}>{language.name}</option>
													);
												}
											)}
										</select>
									</div>
								</div>
								<div className="col">
									<div className="form-group form-group-inline mr-3" role="group">
										<label htmlFor="orderBy">Order By</label>
										<select id="orderBy"
											className="form-control"
											value={this.state.selectedOrderBy.value}
											onChange={(selectedOrderBy) => {
												this.updateOrderBy(selectedOrderBy);
											}}>
											<option value="last_name">Last Name</option>
											<option value="user_id">Internet ID</option>
											<option value="uofm_id">Student ID</option>
										</select>
									</div>
								</div>
							</div>
						</fieldset>

						<fieldset>
							<legend>Run the Report</legend>
							<ButtonGroup className="langtest-report-toggle">
								<Button
									color="primary"
									onClick={() => this.changeOutputOption("display")}
									active={this.state.hideEmailOptions === true}
								>
									Display Report
								</Button>
								<Button
									color="primary"
									onClick={() => this.changeOutputOption("email")}
									active={this.state.hideEmailOptions === false}
								>
									Email Report
								</Button>
							</ButtonGroup>
							{!this.state.hideEmailOptions && (
								<React.Fragment>
									<div className="form-group">
										<label htmlFor="email">Email addresses</label>
										<input
											id="email"
											className="form-control"
											aria-describedby="emailHelp"
											value={this.state.emailTo}
											onChange={(e) => this.setState({emailTo: e.target.value})}
											required
										/>
										<div className="invalid-feedback">
										Please enter an email address.
										</div>
										<small id="emailHelp" className="form-text text-muted">
										Multiple email addresses may be separated by commas
										</small>
									</div>
									<div className="form-group">
										<label htmlFor="emailCc">Carbon Copy (CC) addresses </label>
										<input
											id="emailCc"
											className="form-control"
											aria-describedby="emailCcHelp"
											value={this.state.emailCc}
											onChange={(e) => this.setState({emailCc: e.target.value})}
										/>
										<small id="emailCcHelp" className="form-text text-muted">
										Multiple CC addresses may be separated by commas
										</small>
									</div>
									<div className="form-group">
										<label htmlFor="emailSubject">Email subject line</label>
										<input
											id="emailSubject"
											className="form-control"
											value={this.state.emailSubject}
											onChange={(e) => {
												this.setState({
													emailSubject: e.target.value,
													emailSubjectCustomized: true
												});
											}}
											required
										/>
										<div className="invalid-feedback">
										Please enter an email subject.
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="emailBody">Email message</label>
										<textarea
											id="emailBody"
											aria-describedby="emailBodyHelp"
											className="form-control"
											value={this.state.emailBody}
											onChange={(e) => this.setState({emailBody: e.target.value})}
											required
										/>
										<div className="invalid-feedback">
										Please enter an email message.
										</div>
										<small id="emailBodyHelp" className="form-text text-muted">
										Your chosen report parameters will be automatically added to the email
										message
										</small>
									</div>
									<button
										type="button"
										className="btn btn-primary"
										onClick={(e) => {
											this.generateCustomReport(e, "email");
										}}>
									Send Report
									</button>
								</React.Fragment>
							)}
						</fieldset>
					</form>
				</div>
			</LoadingIndicator.Wrapper>
		);
	}
}
const
	QUICK_REPORT_TYPES = {
		none: {
			name: "None",
			start: null,
			end: null
		},
		today: {
			name: "Today",
			start: moment(0, "HH"),
			end: moment(),
		},
		yesterday: {
			name: "Yesterday",
			start: moment(0, "HH").add(-1, "days"),
			end: moment(24, "HH").add(-1, "days"),
		},
		last_seven_days: {
			name: "Last 7 Days",
			start: moment(0, "HH").add(-7, "days"),
			end: moment(24, "HH").add(-1, "days"),
		},
		last_thirty_days: {
			name: "Last 30 Days",
			start: moment(0, "HH").add(-30, "days"),
			end: moment(24, "HH").add(-1, "days"),
		},
	};
