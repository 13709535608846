import React from "react";

import BaseComponent from "../components/BaseComponent";
import LoadingIndicator from "../components/LoadingIndicator";
import ajax from "../utils/ajax";
import {
	API_BASE_ADDRESS,
} from "../utils/constants";

import "react-datetime/css/react-datetime.css";

export default class EditableActionEditPage extends BaseComponent{

	constructor(props) {
		super(props);
		this.state = {
			action: null,
			pageTitle: "Loading..."
		};
		this.saveEditableAction = this.saveEditableAction.bind(this);
	}

	initializeAction() {
		var newState;
		if (this.props.match.params.id) {
			ajax.get(API_BASE_ADDRESS + "editable_action/" + this.props.match.params.id)
				.then((data)=>{
					newState = {
						action: data.action,
						pageTitle: "Edit action",
						creatingNew: false
					};
					this.addToState(newState);
				});
		} else {
			newState = {
				action: {
					admin_title: "",
					action_url: "",
					action_text: ""
				},
				pageTitle: "Add New Action",
				creatingNew: true
			};
			this.addToState(newState);
		}
	}

	componentDidMount() {
		this.initializeAction();
	}

	saveEditableAction() {
		if (this.state.action.id) {
			ajax.put(API_BASE_ADDRESS + "editable_action/update", this.state.action)
				.then(()=>{
					this.props.history.push("/admin/content/index");
				});
		} else {
			ajax.post(API_BASE_ADDRESS + "editable_action/add", this.state.action)
				.then(()=>{
					this.props.history.push("/admin/content/index");
				});
		}
	}

	render() {
		if (this.state.action == null) {
			return (
				<LoadingIndicator.Element/>
			);
		} else {
			return (
				<React.Fragment>
					<h1>{this.state.pageTitle}</h1>
					<div className="border bg-white p-3">
						<div className="form-group">
							<label htmlFor="admin-title">Admin Title</label>
							<input
								className="form-control"
								id="admin-title"
								type="text"
								value={this.state.action.admin_title}
								onChange={e => {
									const action = this.state.action;
									action.admin_title = e.target.value;
									this.addToState(action);
								}}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="action-text">Action Text</label>
							<input
								className="form-control"
								id="action-text"
								type="text"
								value={this.state.action.action_text}
								onChange={e => {
									const action = this.state.action;
									action.action_text = e.target.value;
									this.addToState(action);
								}}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="action-link">Action Link</label>
							<input
								className="form-control"
								id="action-link"
								type="text"
								value={this.state.action.action_url}
								onChange={e => {
									const action = this.state.action;
									action.action_url = e.target.value;
									this.addToState(action);
								}}
							/>
						</div>
						<button
							className="btn btn-sm btn-primary"
							onClick={this.saveEditableAction}
						>
							Save changes
						</button>

						<a
							className="btn btn-sm btn-outline-primary ml-2"
							role="button"
							href="/admin/content/index"
						>
							Cancel
						</a>
					</div>
				</React.Fragment>
			);
		}
	}
}
