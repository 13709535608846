import React from "react";

import BaseComponent from "../components/BaseComponent";

export default class Announcement extends BaseComponent {

	render() {
		return (
			<div className={"alert alert-" + this.props.style}>
				<h1>{this.props.title}</h1>
				<div dangerouslySetInnerHTML={{__html: this.props.message}} />
			</div>
		);
	}

}