import React from "react";

export default class NotFoundPage extends React.Component {
	render(){
		return(
			<React.Fragment>
				<h1>Page not found</h1>
				<p>Sorry, the page you are looking for could not be found. Please try navigating from the <a href="/">home page.</a></p>
			</React.Fragment>
		);
	}
}
