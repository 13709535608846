import React from "react";
import {API_BASE_ADDRESS} from "../utils/constants";
import ajax from "../utils/ajax";


export default class WritingOverrideCheckbox extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			checked: props.exam.results.exam_info.writing_allowed_override
		};
	}


	updateWritingFlag(flagValue) {
		const params = {checked: flagValue, exam_id: this.props.exam.exam_id, language: this.props.exam.language.name};
		ajax.put(API_BASE_ADDRESS + "/writing_override/update", params)
			.then((result) => {
				if (result.updated) {
					this.setState({checked: flagValue});
				}
			});
	}


	handleCheckboxChange = () => {
		if (!this.state.checked && window.confirm('Are you sure you wish allow student to take the writing test?')) {
			this.updateWritingFlag(true);
		} else if (this.state.checked && window.confirm('Are you sure you wish no longer allow student to take the writing test?')) {
			this.updateWritingFlag(false);
		}
	}


	render() {

		const Checkbox = props => (
			<div className="form-check">
				<input
					type="checkbox"
					className="form-check-input position-static"
					id="writingOverrideCheck"
					aria-label="Writing override checkbox"
					{...props}
				/>
				<label className="form-check-label small text-muted" htmlFor="writingOverrideCheck">Allow Writing
					Portion </label>
			</div>
		)

		return (
			<Checkbox
				checked={this.state.checked}
				onChange={this.handleCheckboxChange}
			/>
		)
	}
}