import $ from "jquery";

/* a wrapper class for handling ajax requests.
	should make it easier for us to change AJAX frameworks if necessary in the future.
 */

export default class ajax{
		static flashSubscriptionCallbacks = [];
		static token = document.querySelector("meta[name=\"csrf-token\"]").content;

		static delete(url, params){
			params["authenticity_token"] = this.token;
			return this.makeRequest("DELETE", url, params);
		}
		static get(url, params){
			return this.makeRequest("GET", url, params);
		}
		static post(url, params){
			params["authenticity_token"] = this.token;
			return this.makeRequest("POST", url, params);
		}
		static put(url, params){
			params["authenticity_token"] = this.token;
			return this.makeRequest("PUT", url, params);
		}
		static _ajaxPromiseHandler = function(ajaxRequestPromise){
			return ajaxRequestPromise.then((result)=>{
				this.handleFlashMessages(result.flash);

				return result.data;
			});
		};
		static makeRequest(requestType, url, params){
			return this._ajaxPromiseHandler(
				$.ajax(
					{
						method: requestType,
						url: url,
						data: params
					}
				)
			);
		}

		//for flashMessages
		static handleFlashMessages(flashMessages){
			if (!flashMessages.length){
				return;
			}
			const flashObjects = flashMessages.map((flashMessage) => {
				return {type: flashMessage[0], text: flashMessage[1]};
			});

			this.flashSubscriptionCallbacks.map((callback) => {

				callback(flashObjects);
			});
		}
		static subscribeToFlashMessages(callback){
			this.flashSubscriptionCallbacks.push(callback);
		}
}
