import React from "react";
import profile_image from "../../../assets/images/profile-image.png";

export default class AssistantStartPanel extends React.Component{
	render(){
		return(
			<div className="card-body">
				<h1>
					Welcome to the EPT Assistant
				</h1>
				<img src={profile_image} className="w-25 my-3" alt="" aria-hidden="true" />
				<p>
					Find out if you need to take the EPT
				</p>
				<a
					className="btn btn-lg btn-primary"
					role="button"
					href="/assistant/language">
					Let&apos;s Get Started
				</a>
			</div>
		);
	}
}
