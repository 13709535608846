import React from "react";
import AssistantTemplate from "./AssistantTemplates";
import LoadingIndicator from "./../LoadingIndicator";
import ajax from "./../../utils/ajax";
import {API_BASE_ADDRESS} from "./../../utils/constants";

export default class AssistantControllerPanel extends React.Component {

	state = {};

	getAssistantData(){
		//make api call to get screen_id, screen contents and student state from the back end
		const lang = this.props.match.params.lang;
		ajax.get(API_BASE_ADDRESS + "/assistant/run/" + lang).then((data)=>{
			this.setState({outcome:data.outcome});
		});
	}
	componentDidMount(){
		this.getAssistantData();
	}
	render(){
		return(
			<span>
				{!this.state.outcome && (
					<LoadingIndicator.Element
						type={LoadingIndicator.TYPES.fill}
						size={LoadingIndicator.SIZES.large}/>
				)}
				{this.state.outcome && (
					/* To re-enable display of the "top" content type, change showTopMessage to true. See GitHub issue #506 for details. */
					<AssistantTemplate outcome={this.state.outcome} showTopMessage={false}/>
				)}
			</span>
		);
	}
}
