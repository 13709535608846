import React from "react";

import ajax from "../utils/ajax";
import {API_BASE_ADDRESS, FAQ_CATEGORIES} from "../utils/constants";
import TabManager from "../components/TabManager";
import FAQAccordion from "../components/FAQAccordion";
import LoadingIndicator from "../components/LoadingIndicator";

export default class FrequentlyAskedQuestionsPage extends React.Component {

	constructor() {
		super();

		this.state = {};
		this.getFAQs = this.getFAQs.bind(this);
	}

	getFAQs() {
		ajax.get(API_BASE_ADDRESS + "faqs/all")
			.then((data)=>{
				this.setState(data);
			});
	}

	componentDidMount() {
		this.getFAQs();
	}

	render(){
		let groupedFAQs = {};
		if (this.state.faqs) {
			this.state.faqs.map((faq, i) => {
				if (!groupedFAQs[faq.category]) {
					groupedFAQs[faq.category] = [];
				}
				groupedFAQs[faq.category][i] = faq;
			});
		}
		const tabs = Object.keys(groupedFAQs).map((group, i) => {
			return {
				title: FAQ_CATEGORIES[group],
				content: <FAQAccordion faqs={groupedFAQs[group]} id={i} key={i} />
			};
		});

		return (
			<React.Fragment>
				<h1 className="mb-3">Frequently Asked Questions</h1>
				{ tabs.length > 0? (
					<TabManager
						tabs={tabs}
						displayType="vertical"
						paneStyle="plain"
						baseURL={this.props.match.url}
						queryString={this.props.location.search}
					/>
				) : (
					<LoadingIndicator.Element type={LoadingIndicator.TYPES.fill} delay={1000}/>
				)}
			</React.Fragment>
		);
	}
}