import React from "react";

import BaseComponent from "../components/BaseComponent";
import EditableContentUse from "../components/EditableContentUse";
import LoadingIndicator from "../components/LoadingIndicator";
import ajax from "../utils/ajax";
import {
	API_BASE_ADDRESS,
	CRITERIA_DESCRIPTIONS,
	EDITABLE_CONTENT_TYPES,
	EPT_LANGUAGES
} from "../utils/constants";

import "react-datetime/css/react-datetime.css";
import truncate from "truncate-html";
import striptags from "striptags";

export default class EditableContentUseEditPage extends BaseComponent{

	constructor(props) {
		super(props);
		this.state = {
			content: null,
			uses: null,
			new_content_url: "",
			new_language_id: "",
			new_criteria: "",
			pageTitle: "Loading..."
		};
		this.deleteEditableContentUse = this.deleteEditableContentUse.bind(this);
		this.addEditableContentUse = this.addEditableContentUse.bind(this);
	}

	initializeContent() {
		var newState;
		if (this.props.match.params.id) {
			ajax.get(API_BASE_ADDRESS + "editable_content/" + this.props.match.params.id)
				.then((data)=>{
					newState = {
						content: data.content,
						uses: data.uses,
						pageTitle: "Edit content usage",
					};
					this.addToState(newState);
				});
		}
	}

	componentDidMount() {
		this.initializeContent();
	}

	deleteEditableContentUse(id) {
		if (window.confirm("Are you sure you want to delete this content use?")) {
			ajax.delete(API_BASE_ADDRESS + "editable_content_use/delete/", {id: id})
				.then((data) => {
					if (data.deleted) {
						this.initializeContent();
					}
				});
		}
	}

	addEditableContentUse() {
		let add = true;

		// check for existing records
		if (this.state.content.content_type == "page") {
			this.state.uses.forEach((use) => {
				if (use.content_url == this.state.new_content_url) {
					alert("This URL already exists for this content.");
					add = false;
				}
			});
		} else {
			this.state.uses.forEach((use) => {
				if (use.criteria == this.state.new_criteria && use.language_id == this.state.new_language_id) {
					alert("This combination of criteria and language already exists for this piece of editable content.");
					add = false;
				}
			});
		}

		if (add) {
			// add the new use
			let new_content_use = {
				editable_content_id: this.state.content.id,
				content_type: this.state.content.content_type,
				content_url: this.state.new_content_url,
				criteria: this.state.new_criteria,
				language_id: this.state.new_language_id
			};
			ajax.post(API_BASE_ADDRESS + "editable_content_use/add", new_content_use)
				.then(()=>{
					// reset state for next addition
					let newState = {
						new_content_url: "",
						new_language_id: "",
						new_criteria: ""
					};
					this.addToState(newState);
					this.initializeContent();
				});
		}
	}

	render() {
		if (this.state.content == null) {
			return (
				<LoadingIndicator.Element/>
			);
		} else {
			//TODO display usage list in a table?
			return (
				<React.Fragment>
					<h1>{this.state.pageTitle}</h1>
					<div className="border bg-white p-3">
						<p><strong>Admin title:</strong> {this.state.content.admin_title}</p>
						<p><strong>Content type:</strong> {EDITABLE_CONTENT_TYPES[this.state.content.content_type]}</p>
						<p><strong>Content preview:</strong> <span dangerouslySetInnerHTML={{__html: truncate(striptags(this.state.content.html), 200)}}></span></p>
						{
							this.state.uses.length > 0 && (
								<React.Fragment>
									<h2>Current Usage</h2>
									<ul>
										{
											this.state.uses.map((use) => {
												return (
													<li key={use.id} className="mb-1 row">
														<div className="col-3 border-bottom">
															<EditableContentUse
																className="d-inline"
																content_type={this.state.content.content_type}
																editable_content_id={this.state.content.id}
																language={use.language}
																criteria={use.criteria}
																content_url={use.content_url}
															/>
														</div>
														<div className="col">
															<button
																className="btn btn-sm btn-primary ml-1"
																onClick={() => this.deleteEditableContentUse(use.id)}
															>
																Delete
															</button>
														</div>
													</li>
												);
											})
										}
									</ul>
								</React.Fragment>
							)
						}
						<h2>Add Usage</h2>
						{ this.state.content.content_type == "page" ? (
							<div>
								<div className="form-group">
									<label htmlFor="url">URL</label>
									<input
										className="form-control"
										id="url"
										type="text"
										value={this.state.new_content_url}
										onChange={e => {
											const new_content_url = e.target.value;
											this.addToState({new_content_url: new_content_url});
										}}
									/>
								</div>
								<button
									className="btn btn-primary ml-1"
									onClick={this.addEditableContentUse}
								>
								Add
								</button>
							</div>
						) : (
							<div>
								<div className="form-group">
									<label htmlFor="criteria">Criteria</label>
									<select
										className="form-control"
										id="criteria"
										value={this.state.new_criteria}
										onChange={(e)=> this.setState({new_criteria: e.target.value})}
									>
										<option value="">--choose</option>
										{
											Object.keys(CRITERIA_DESCRIPTIONS).map((criteria) => {
												return (
													<option key={criteria} name={criteria} value={criteria}>{criteria}: {CRITERIA_DESCRIPTIONS[criteria]}</option>
												);
											})
										}
									</select>
								</div>
								<div className="form-group">
									<label htmlFor="language">Language</label>
									<select
										className="form-control"
										id="language"
										value={this.state.new_language_id}
										onChange={(e)=> this.setState({new_language_id: e.target.value})}
									>
										<option value="">--choose</option>
										{
											Object.keys(EPT_LANGUAGES).map((language_id) => {
												return (
													<option key={language_id} name={language_id} value={language_id}>{EPT_LANGUAGES[language_id]}</option>
												);
											})
										}
									</select>
								</div>
								<button
									className="btn btn-primary mb-3"
									onClick={this.addEditableContentUse}
								>
									Add
								</button>
							</div>
						)}

						<a
							className="btn btn-outline-primary"
							href="/admin/content/index"
							role="button"
						>
							Done
						</a>
					</div>
				</React.Fragment>
			);
		}
	}
}
