import React from "react";
import RichTextEditor from "react-rte";

import BaseComponent from "../components/BaseComponent";
import LoadingIndicator from "../components/LoadingIndicator";
import ajax from "../utils/ajax";
import {
	API_BASE_ADDRESS,
	EDITABLE_CONTENT_TYPES,
	RICH_TEXT_TOOLBAR
} from "../utils/constants";

import "react-datetime/css/react-datetime.css";

export default class EditableContentEditPage extends BaseComponent{

	constructor(props) {
		super(props);
		this.state = {
			content: null,
			rte: null,
			pageTitle: "Loading..."
		};
		this.saveEditableContent = this.saveEditableContent.bind(this);
	}

	initializeContent() {
		var newState;
		if (this.props.match.params.id) {
			ajax.get(API_BASE_ADDRESS + "editable_content/" + this.props.match.params.id)
				.then((data)=>{
					newState = {
						content: data.content,
						rte: {
							html: RichTextEditor.createValueFromString(
								data.content.html, "html")
						},
						pageTitle: "Edit content of type '" + data.content.content_type + "'",
						creatingNew: false
					};
					this.addToState(newState);
				});
		} else {
			newState = {
				content: {
					admin_title: "",
					html: ""
				},
				rte: {
					html: RichTextEditor.createEmptyValue()
				},
				pageTitle: "Add New Content",
				creatingNew: true
			};
			this.addToState(newState);
		}
	}

	componentDidMount() {
		this.initializeContent();
	}

	saveEditableContent() {
		if (this.state.content.id) {
			ajax.put(API_BASE_ADDRESS + "editable_content/update", this.state.content)
				.then(()=>{
					this.props.history.push("/admin/content/index");
				});
		} else {
			ajax.post(API_BASE_ADDRESS + "editable_content/add", this.state.content)
				.then(()=>{
					this.props.history.push("/admin/content/index");
				});
		}
	}

	render() {
		if (this.state.content == null) {
			return (
				<LoadingIndicator.Element/>
			);
		} else {
			return (
				<React.Fragment>
					<h1>{this.state.pageTitle}</h1>
					<div className="border bg-white p-3">
						<div className="form-group">
							<label htmlFor="admin-title">Admin Title</label>
							<input
								className="form-control"
								id="admin-title"
								type="text"
								value={this.state.content.admin_title}
								onChange={e => {
									const content = this.state.content;
									content.admin_title = e.target.value;
									this.addToState(content);
								}}
							/>
						</div>
						<div className="form-group">
							<label htmlFor="rte">Content</label>
							<RichTextEditor
								id="rte"
								value={this.state.rte.html}
								toolbarConfig={RICH_TEXT_TOOLBAR}
								onChange={e => {
									const rte = this.state.rte;
									rte.html = e;
									this.addToState(rte);
								}}
								onBlur={() => {
									const content = this.state.content;
									content.html =
										this.state.rte.html.toString("html");
									this.addToState(content);
								}}
							/>
						</div>
						{
							this.state.creatingNew ? (
								<div className="form-group">
									<label htmlFor="content-type">Content Type</label>
									<select id="content-type"
										className="form-control"
										value={this.state.content.content_type}
										onChange={e => {
											const content = this.state.content;
											content.content_type = e.target.value;
											this.addToState(content);
										}}
									>
										<option value="">Choose...</option>
										{
											Object.keys(EDITABLE_CONTENT_TYPES).map((type) => {
												return (
													<option key={type} name={type}
														value={type}>{EDITABLE_CONTENT_TYPES[type]}</option>
												);
											})
										}
									</select>
								</div>
							) : (
								null
							)
						}
						<button
							className="btn btn-sm btn-primary"
							onClick={this.saveEditableContent}
						>
							Save changes
						</button>

						<a
							className="btn btn-sm btn-outline-primary ml-1"
							role="button"
							href="/admin/content/index"
						>
							Cancel
						</a>
					</div>
				</React.Fragment>
			);
		}
	}
}
