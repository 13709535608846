import React from "react";

export default class NoRecordsForStudentPage extends React.Component {
	render(){
		return(
			<React.Fragment>
				<h1>
					No scores available
				</h1>
				<p>
					Your exam may still be in the grading process or you may not have taken the EPT.
				</p>
				<div>
					<a
						href="/"
						role="button"
						className="btn btn-lg btn-primary">
						Go to the Home Page
					</a>
					<a
						href="/ept/take"
						role="button"
						className="btn btn-lg btn-primary ml-2">
						Take the EPT
					</a>
				</div>
			</React.Fragment>
		);
	}
}
