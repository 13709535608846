import React from "react";
import BaseComponent from "./../components/BaseComponent";
import EditableContentTableView from "../components/EditableContentTableView";
import EditableActionTableView from "../components/EditableActionTableView";
import TabManager from "../components/TabManager";

export default class EditableContentAdminPage extends BaseComponent{

	render(){
		return (
			<React.Fragment>

				<h1>Manage Editable Content and Actions</h1>

				<TabManager
					tabs={[
						{
							title:"Editable Content",
							content:(
								<EditableContentTableView />
							)
						},
						{
							title:"Editable Actions",
							content:(
								<EditableActionTableView />
							)
						}
					]}
					baseURL={this.props.match.url}
					queryString={this.props.location.search}
				/>

			</React.Fragment>
		);
	}
}
