import React from "react";
import BaseComponent from "./BaseComponent";

export default class LoggedInUserNav extends BaseComponent {

	render() {
		const user = this.props.user;
		return(
			<div className="ml-md-auto small">
				{user? (
					<React.Fragment>
						Logged in as {user.display_name} <a className="btn btn-primary btn-sm" href="/signout" role="button">Logout</a>
					</React.Fragment>
				) : (
					<a className="btn btn-primary" href={"/signin?target=" + encodeURI(window.location.href)} role="button">Login</a>
				)}
			</div>
		);
	}
}
