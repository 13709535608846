import React from "react";

export default class StudentSearchBar extends React.Component{

	render(){
		return (
			<form onSubmit={(e) => e.preventDefault()}>
				<div className="form-group">
					<label htmlFor="student-search-bar">
						<h1>Student Search</h1>
					</label>
					<input
						id="student-search-bar"
						className="form-control form-control-lg"
						{...this.props}
						placeholder="start typing a name, student ID, or internet ID"
					/>
				</div>
			</form>
		);
	}
}
