import React from "react";
import BaseComponent from "./../components/BaseComponent";
import StudentSearchBar from "../components/StudentSearchBar";
import StudentTableView from "../components/StudentTableView";
import LoadingIndicator from "./../components/LoadingIndicator";
import ajax from "./../utils/ajax";
import {API_BASE_ADDRESS} from "../utils/constants";
import { ExclamationCircleIcon } from "../components/Icons";

export default class StudentSearchPage extends BaseComponent{

	state = {};
	timeout = null;

	onSearchBarChange = (e) => {
		const query = e.target.value;

		if (this.timeout){
			clearTimeout(this.timeout);
		}

		this.timeout = setTimeout( ()=>{

			if (!query.length){
				this.addToState({students:null});
				return;
			}

			this.addToState({query: query});

			this.fetchSearchResults(query);
		}, 600);
	};
	fetchSearchResults (query){
		this.setState({hasPendingRequest:true});
		var request =  ajax.get(API_BASE_ADDRESS + "students/search/" + query);
		request.then((results)=>{
			this.request = null;
			this.timeout = null;
			this.addToState({students: results.students});
		}).done(()=>{
			this.setState({hasPendingRequest:false});
		});
	}
	render(){
		return(
			<React.Fragment>
				<StudentSearchBar onChange={this.onSearchBarChange}/>
				<LoadingIndicator.Wrapper show={this.state.hasPendingRequest}>
					{this.state.students ? (//a search was made
						<React.Fragment>
							{this.state.students.length ? (
								<StudentTableView data={this.state.students} history={this.props.history}/>
							) : (
								<div className="text-center">
									<ExclamationCircleIcon className="my-3" />
									<div>
										No results for <strong>{this.state.query}</strong>
									</div>
								</div>
							)}
						</React.Fragment>
					) : (//placeholder
						<div className="text-muted text-center">
							Search results will appear here
						</div>
					)}
				</LoadingIndicator.Wrapper>
			</React.Fragment>
		);
	}

}
