import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment/moment";
import DateTime from "react-datetime";
import RichTextEditor from "react-rte";

import LoadingIndicator from "./../LoadingIndicator";
import ajax from "../../utils/ajax";
import {ANNOUNCEMENT_STYLES, API_BASE_ADDRESS, RICH_TEXT_TOOLBAR} from "../../utils/constants";

export default class EditAnnouncementModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			announcement: null,
			valid_start_date: true,
			valid_end_date: true,
			valid_message: true,
			rte: null,
			pageTitle: "Loading...",
			toggleButtonText: ""
		};

		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	componentDidMount() {
		let newState;
		if (this.props.announcement != null) {
			newState = {
				announcement: this.props.announcement,
				rte: {
					message: RichTextEditor.createValueFromString(
						this.props.announcement.message, "html")
				},
				pageTitle: "Edit announcement",
				toggleButtonText: "Edit"
			};
		} else {
			newState = {
				announcement: {
					title: "",
					style: "primary",
					message: "",
					active_start: null,
					active_end: null
				},
				rte: {
					message: RichTextEditor.createEmptyValue()
				},
				pageTitle: "Add new announcement",
				toggleButtonText: "Add new announcement"
			};
		}
		this.setState(newState);
	}

	validDatesEntered = () => {
		let momentStart = moment(this.state.announcement.active_start);
		let momentEnd = moment(this.state.announcement.active_end);
		let validStartDate = momentStart.isValid();
		let validEndDate = momentEnd.isValid() && momentEnd.isAfter(momentStart);
		this.setState({ valid_start_date: validStartDate, valid_end_date: validEndDate } );
		return validStartDate && validEndDate;
	};

	validMessageEntered = () => {
		let validMessage = this.state.announcement.message !== "";
		this.setState( {valid_message: validMessage} );
		return validMessage;
	};

	saveAnnouncement = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let validDates = this.validDatesEntered();
		let validMessage = this.validMessageEntered();
		let form = document.getElementById("announcement-form");
		if (!validDates || !validMessage || form.checkValidity() === false) {
			this.setState({form_class: "was-validated"});
		} else {
			if (this.state.announcement.id) {
				ajax.put(API_BASE_ADDRESS + "announcements/update", this.state.announcement)
					.then((data) => {
						if (data.updated) {
							this.props.onSaveAnnouncement();
							this.toggle();
						}
					});
			} else {
				ajax.post(API_BASE_ADDRESS + "announcements/add", this.state.announcement)
					.then((data) => {
						if (data.created) {
							this.props.onSaveAnnouncement();
							this.toggle();
						}
					});
			}
		}
	};

	render() {
		let invalidStartDateDisplay = "block";
		let invalidEndDateDisplay = "block";
		let invalidMessageDisplay = "block";
		let dateInputProps = {className: "form-control custom-validated", required: true};

		if (this.state.valid_start_date) {
			invalidStartDateDisplay = "none";
		}
		if (this.state.valid_end_date) {
			invalidEndDateDisplay = "none";
		}
		if (this.state.valid_message) {
			invalidMessageDisplay = "none";
		}

		return (
			<React.Fragment>
				<button
					className="btn btn-primary btn-sm"
					type="button"
					onClick={this.toggle}>{this.state.toggleButtonText}
				</button>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					{this.state.announcement == null? (
						<React.Fragment>
							<ModalHeader toggle={this.toggle}>
								Loading, please wait...
							</ModalHeader>
							<ModalBody>
								<LoadingIndicator.Element/>
							</ModalBody>
						</React.Fragment>
					) : (
						<React.Fragment>
							<ModalHeader toggle={this.toggle}>{this.state.pageTitle}</ModalHeader>
							<ModalBody>
								<form id="announcement-form" className={this.state.form_class} noValidate>
									<div className="form-group">
										<label htmlFor="title">Title</label>
										<input
											className="form-control"
											id="title"
											type="text"
											value={this.state.announcement.title}
											onChange={e => {
												const announcement = this.state.announcement;
												announcement.title = e.target.value;
												this.setState(announcement);
											}}
											required
										/>
										<div className="invalid-feedback">Please enter a title.</div>
									</div>
									<div className="form-group">
										<legend className="styled-as-label">Style</legend>
										{Object.keys(ANNOUNCEMENT_STYLES).map((style) => {
											return (
												<div
													key={style}
													className={"form-check form-check-inline p-1 alert-" + style}>
													<input className="form-check-input"
														type="radio"
														name="style_options"
														id={style}
														checked={this.state.announcement.style === style}
														onChange={e => {
															const announcement = this.state.announcement;
															announcement.style = e.target.value;
															this.setState(announcement);
														}}
														value={style}
													/>
													<label
														className="form-check-label"
														htmlFor={style}>{ANNOUNCEMENT_STYLES[style]}</label>
												</div>
											);
										})}
									</div>
									<div className="form-group">
										<label htmlFor="message">Message</label>
										<RichTextEditor
											id="message"
											value={this.state.rte.message}
											toolbarConfig={RICH_TEXT_TOOLBAR}
											onChange={e => {
												const rte = this.state.rte;
												rte.message = e;
												this.setState(rte);
											}}
											onBlur={() => {
												const announcement = this.state.announcement;
												announcement.message =
								this.state.rte.message.toString("html");
												this.setState(announcement);
											}}
											valid={this.state.valid_message}
										/>
										<div className={"invalid-feedback"} style={{"display": invalidMessageDisplay}}>
											Please enter a message.
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="active-start">Active Start</label>
										<DateTime
											inputProps={dateInputProps}
											id="active-start"
											value={moment(this.state.announcement.active_start)}
											onChange={e => {
												const announcement = this.state.announcement;
												announcement.active_start = moment(e.valueOf()).format();
												this.setState(announcement);
											}}
										/>
										<div className={"invalid-feedback"} style={{"display": invalidStartDateDisplay}}>
											Start date must be present.
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="active-end">Active End</label>
										<DateTime
											inputProps={dateInputProps}
											id="active-end"
											value={moment(this.state.announcement.active_end)}
											onChange={e => {
												const announcement = this.state.announcement;
												announcement.active_end = moment(e.valueOf()).format();
												this.setState(announcement);
											}}
										/>
										<div className={"invalid-feedback"} style={{"display": invalidEndDateDisplay}}>
											End date must be present, and later than start date.
										</div>
									</div>
								</form>
							</ModalBody>
							<ModalFooter>
								<button
									className="btn btn-primary ml-sm-2"
									type="button"
									key={"save_" + this.state.announcement.id}
									onClick={this.saveAnnouncement}
								>
									Save changes
								</button>
								<button
									className="btn btn-outline-secondary ml-sm-2"
									type="button"
									key={"cancel_" + this.state.announcement.id}
									onClick={this.toggle}
								>
									Cancel
								</button>
							</ModalFooter>
						</React.Fragment>
					)}
				</Modal>
			</React.Fragment>
		);
	}
}

EditAnnouncementModal.defaultProps = {
	onSaveAnnouncement: () => {
		return;
	}
};