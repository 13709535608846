import React from "react";

export default class ReportStatsView extends React.Component{

	render(){
		const stats = this.props.stats;

		if (stats && stats.count > 0) {
			return(
				<div className="text-center mx-auto">
					<ul className="list-inline m-0">
						<li className="list-inline-item">Passing: <strong>{stats.passing}</strong></li>
						<li className="list-inline-item">High Passing: <strong>{stats.high_passing}</strong></li>
						<li className="list-inline-item">Failing: <strong>{stats.failing}</strong></li>
						<li className="list-inline-item">Incomplete: <strong>{stats.incomplete}</strong></li>
						<li className="list-inline-item">Total: <strong>{stats.count}</strong></li>
					</ul>
				</div>
			);
		} else {
			return null;
		}
	}

}
