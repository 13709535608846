import React from "react";
import ajax from "./../utils/ajax";
import BaseComponent from "./../components/BaseComponent.js";
import NoRecordsForStudentPage from "./NoRecordsForStudentPage";
import TabManager from "./../components/TabManager.js";
import StudentPageExamTabContent from "./../components/StudentPageExamTabContent";
import LoadingIndicator from "./../components/LoadingIndicator";
import capitalize from "lodash/capitalize";
import invert from "lodash/invert";
import ActiveAnnouncements from "./../components/ActiveAnnouncements";
import StudentPageCurrentStudentContext from "./../contexts/StudentPageCurrentStudentContext";
import {API_BASE_ADDRESS, EXAM_TYPES} from "../utils/constants";
import LoggedInUserContext from "./../contexts/LoggedInUserContext";

class StudentPage extends BaseComponent{

	state = {};

	componentDidMount(){
		this.fetchStudent();
	}

	fetchStudent = ()=>{
		let uofm_id = this.props.match.params.uofm_id || this.props.uofm_id;
		if (uofm_id) {
			// Only retrieve student data if the ID is not null. Otherwise,
			// unauthenticated users will be shown dummy results which exist in
			// the DB for uofm_id=0.
			ajax.get(API_BASE_ADDRESS + "students/uofm_id/" + uofm_id)
				.then((_student) => {
					const student = _student || {};
					this.addToState({student});
				});
		} else {
			this.addToState({student: {}});
		}
	};
	getExamsByLanguageAndExamTypeDictionary = (examsArray)=>{
		let examsDictionary = {};

		examsArray.map((exam)=>{
			let language = exam.language.name + " - " + invert(EXAM_TYPES)[exam.language.type];

			!examsDictionary[language] && (examsDictionary[language] = []);

			examsDictionary[language].push(exam);
		});

		return examsDictionary;
	};
	render(){
		if (this.state.student === undefined) {
			return (
				<LoadingIndicator.Element type={LoadingIndicator.TYPES.fill} delay={1000}/>
			);
		}
		else if (this.state.student === null || (!(this.state.student.exams && this.state.student.exams.length))) {
			//if the person is not a student OR if the user is a student but has not exams/scores
			return (<NoRecordsForStudentPage/>);
		}
		const {exams, ...student} = this.state.student;
		const examsByLanguageAndExamType = this.getExamsByLanguageAndExamTypeDictionary(exams);

		var pageHeader;
		var hideSectionScores;
		if (this.props.loggedInUser != null && this.props.loggedInUser.emplid == student.uofm_id) {
			pageHeader = "Your Results";
			hideSectionScores = true;
		} else {
			pageHeader = "Student Results";
			hideSectionScores = false;
		}

		const tabs = Object.keys(examsByLanguageAndExamType).map((key)=>{
			var currentSubjectExams = examsByLanguageAndExamType[key];

			return {
				title: key,
				customIndex: key.replace(/\s/g, "").toLowerCase(),
				content:(
					<StudentPageExamTabContent
						key={key}
						refreshStudentPageContents={this.fetchStudent}
						exams={currentSubjectExams}
						hideSectionScores={hideSectionScores}
					/>
				)
			};
		});
		return(
			<React.Fragment>
				<ActiveAnnouncements />
				<StudentPageCurrentStudentContext.Provider value={student}>
					<h1>
						{pageHeader}<br/>
						<ul className="list-inline small text-muted">
							<li className="list-inline-item with-separator"><span className="sr-only">Name: </span>{student.first_name + " " + capitalize(student.last_name)}</li>
							<li className="list-inline-item with-separator"><span className="sr-only">Internet ID: </span>{student.x500}</li>
							<li className="list-inline-item with-separator"><span className="sr-only">Student ID: </span>{student.uofm_id}</li>
						</ul>
					</h1>
					<TabManager
						tabs={tabs}
						displayType="vertical"
						baseURL={this.props.match.url}
						queryString={this.props.location.search}
					/>
				</StudentPageCurrentStudentContext.Provider>
			</React.Fragment>
		);
	}

}

export default props => (
	<LoggedInUserContext.Consumer>
		{loggedInUser => (
			<StudentPage {...props} loggedInUser={loggedInUser}/>
		)}
	</LoggedInUserContext.Consumer>
);
