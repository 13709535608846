import React from "react";
import ajax from "./../utils/ajax";
import { Alert } from "reactstrap";

export default class FlashMessagesContainer extends React.Component{

	state = {
		index:0,
		flashMessages:[]
	};

	componentDidMount() {
		ajax.subscribeToFlashMessages((_flashMessages) => {
			let index = this.state.index;
			let flashMessages = [].concat(_flashMessages);

			flashMessages.map((flashMessage)=>{
				flashMessage.id = index++;
			});

			this.setState({
				index: index,
				flashMessages: this.state.flashMessages.concat(flashMessages)
			});
		});
	}

	render() {
		return(
			<div className="position-fixed centered-alert-wrapper">
				{this.state.flashMessages.reverse().map((flashMessage)=>{
					return (
						<FlashMessage
							key={flashMessage.id}
							message={{...flashMessage}}
							timeout={7000}
						/>
					);
				})}
			</div>
		);
	}
}

class FlashMessage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: true
		};

		this.onDismiss = this.onDismiss.bind(this);
	}

	componentDidMount() {
		this.timer = setTimeout(
			this.onDismiss,
			this.props.timeout
		);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	onDismiss() {
		this.setState({ visible: false });
	}

	render() {
		let alertColor;

		switch(this.props.message.type) {
		case "success":
			alertColor = "success";
			break;
		case "error":
			alertColor = "danger";
			break;
		default:
			alertColor = "info";
		}

		return (
			<Alert color={alertColor} isOpen={this.state.visible} toggle={this.onDismiss}>
				{this.props.message.text}
			</Alert>
		);
	}

}