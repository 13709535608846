import React from "react";
import BaseComponent from "./BaseComponent";
import BootstrapTable from "react-bootstrap-table-next";
import capitalize from "lodash/capitalize";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

export default class StudentTableView extends BaseComponent {

	state = {};

	generateColumns(){
		const columns = [
			{
				text: "Student ID",
				dataField: "uofm_id",
				formatter: (cellContent, row) => {
					let href = "/admin/students/" + row.uofm_id;
					return(
						<a
							href={href}
							target="_blank"
							rel="noopener noreferrer"
						>
							{row.uofm_id}
						</a>
					);
				}
			},
			{
				text: "First Name",
				dataField: "first_name"
			},
			{
				text: "Middle Initial",
				dataField: "middle_init"
			},
			{
				text: "Last Name",
				dataField: "last_name",
				formatter: (cellContent, row) => capitalize(row.last_name)
			},
			{
				text: "Internet ID",
				dataField: "x500"
			},
			{
				text: "Exams Taken",
				dataField: "exams_taken"
			}
		];
		return columns;
	}

	render() {
		if (!this.props.data){
			return (
				<div> no results </div>
			);
		}

		return (
			<BootstrapTable
				boostrap4
				keyField="uofm_id"
				data={ this.props.data }
				columns={ this.generateColumns() }
				bordered={ false }
				classes="mt-3 bg-white"
			/>
		);
	}
}