import React from "react";
import moment from "moment/moment";
import {unparse} from "papaparse";

import BaseComponent from "./../components/BaseComponent";
import GenerateReportPanel from "./../components/GenerateReportPanel";
import LoadingIndicator from "./../components/LoadingIndicator";
import ReportTableView from "./../components/ReportTableView";
import ReportStatsView from "./../components/ReportStatsView";
import ajax from "./../utils/ajax";
import {
	GRADE_TYPES,
	DATE_TIME_FORMAT_SHORT
} from "../utils/constants";
import { ExclamationCircleIcon, CheckCircleIcon } from "../components/Icons";

export default class ReportsPage extends BaseComponent {

	state = {
		hasPendingRequest: false,
		isOpen: false
	};
	generateNewReport = (apiString) => {
		this.addToState({hasPendingRequest: true});
		return ajax.get(apiString)
			.then((response) => {
				this.addToState({data: response.report, status_message: response.report_status});
			}).done(() => {
				this.addToState({hasPendingRequest: false});
			});
	};

	generateFilename(contentType) {
		return this.state.data.exam_type.toUpperCase() + "_Report_" + contentType + "_generated_on_" + moment().format("MM-DD-YYYY") + ".csv";
	}

	exportAllColumnsToCSV = () => {
		const filename = this.generateFilename("All_Columns");
		const csvData = this.state.data.ordered_exams_result.map((row) => {
			const {student_info, exam_info, reading_plus_listening, reading, listening, writing, overall} = row;
			return {
				Name: student_info.fullname,
				"Student ID": student_info.student_id,
				x500: student_info.x500,

				Language: exam_info.name,
				Exam: exam_info.exam_type,

				Reading: `${reading.score} - ${GRADE_TYPES[reading.status]}`,
				"Reading Date": reading.examdate || "N/A",

				Listening: `${listening.score} - ${GRADE_TYPES[listening.status]}`,
				"Listening Date": listening.examdate || "N/A",

				Composite: `${reading_plus_listening.score} - ${GRADE_TYPES[reading_plus_listening.status]}`,
				"Pass Composite": reading_plus_listening.passing,

				Writing: GRADE_TYPES[writing.status],
				"Writing Date": writing.examdate || "N/A",

				Overall: GRADE_TYPES[overall]
			};
		});
		this.triggerCSVDownloadFromDataString(filename, unparse(csvData));
	};
	exportStudentIDsToCSV = () => {
		const filename = this.generateFilename("Student_IDs_Only");
		const csvData = this.state.data.ordered_exams_result.map((row) => ({"Student ID": row.student_info.student_id}));
		this.triggerCSVDownloadFromDataString(filename, unparse(csvData));
	};

	triggerCSVDownloadFromDataString(filename, dataString) {
		const encodedUri = encodeURI("data:text/csv;charset=utf-8," + dataString);
		if (navigator.msSaveBlob) { // IE 11 & Edge
			navigator.msSaveBlob(encodedUri, filename);
		} else {
			let link = document.createElement("a");
			link.setAttribute("href", encodedUri);
			link.setAttribute("download", filename);
			// Firefox needs the link to be present on the DOM
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	render() {
		return (
			<React.Fragment>
				<h1> Reports (EPT Only) </h1>
				<LoadingIndicator.Wrapper show={this.state.hasPendingRequest}>
					<GenerateReportPanel onShouldGenerateNewReport={this.generateNewReport}/>
				</LoadingIndicator.Wrapper>
				<LoadingIndicator.Wrapper show={this.state.hasPendingRequest}>
					{(this.state.data) ? (
						<div id="results-container">
							<div id="results-summary" className="border bg-light text-center medium p-1 mb-3">
								{(this.state.data.from_date) ? (
									<div>
										Overall Results for Dates: {
											moment(this.state.data.from_date).format(DATE_TIME_FORMAT_SHORT)
										} to {
											moment(this.state.data.to_date).format(DATE_TIME_FORMAT_SHORT)
										}
									</div>
								) : null}
								<ReportStatsView stats={this.state.data.stats}/>
							</div>
							{(this.state.data.ordered_exams_result.length) ? (
								<div>
									<div className="dropdown">
										<button
											className="btn btn-primary dropdown-toggle"
											type="button"
											id="dropdownMenuButton"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false">
											Download File
										</button>
										<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<a id="download-all" className="dropdown-item" onClick={this.exportAllColumnsToCSV}>All
												Columns</a>
											<a id="download-ids" className="dropdown-item" onClick={this.exportStudentIDsToCSV}>IDs
												Only</a>
										</div>
									</div>
									<ReportTableView data={this.state.data.ordered_exams_result} history={this.props.history}/>
								</div>
							) : (
								<div className="text-center">
									<ExclamationCircleIcon className="my-3"/>
									<div>
										No results found
									</div>
								</div>
							)}
						</div>
					) : null}
					{this.state.status_message ? (
						<div className="text-center">
							<CheckCircleIcon className="my-3"/>
							<div>
								{this.state.status_message}
							</div>
						</div>
					) : null}
				</LoadingIndicator.Wrapper>
			</React.Fragment>
		);
	}
}
