import React from "react";
import {NavLink as DOMNavLink} from "react-router-dom";
import LoggedInUserContext from "./../contexts/LoggedInUserContext";
import LoggedInUserNav from "./LoggedInUserNav";
import { Collapse, Container, Navbar, Nav, NavItem, NavLink } from "reactstrap";
import { USER_ROLE_TYPES } from "./../utils/constants";
import { BarsIcon } from "./Icons";

export default class NavigationBarForHomePage extends React.Component{
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false
		};
	}
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}
	render(){
		const activeClassName = "active";
		return(
			<Navbar color="white" light expand="md" className="langtest-nav border-bottom">
				<Container className="px-sm-3">
					<button className="d-md-none btn btn-sm btn-primary" onClick={this.toggle}>
						<BarsIcon /> Menu
					</button>
					<LoggedInUserContext.Consumer>
						{ loggedInUser =>(
							<React.Fragment>
								<Collapse isOpen={this.state.isOpen} navbar>
									<Nav navbar>
										<NavItem>
											<NavLink exact tag={DOMNavLink} to="/home" activeClassName={activeClassName}>
												Home
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink tag={DOMNavLink} to="/ept/eligibility" activeClassName={activeClassName}>
												Eligibility
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink tag={DOMNavLink} to="/faq?tab=0" activeClassName={activeClassName}>
												FAQ
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink tag={DOMNavLink} to="/info/contact" activeClassName={activeClassName}>
												Contact Us
											</NavLink>
										</NavItem>
										{loggedInUser && (loggedInUser.role == USER_ROLE_TYPES.admin || loggedInUser.role == USER_ROLE_TYPES.staff || loggedInUser.role == USER_ROLE_TYPES.advisor) && (
											<NavItem>
												<NavLink tag={DOMNavLink} to="/admin/students">
													Admin Tools
												</NavLink>
											</NavItem>
										)}
										{loggedInUser && (loggedInUser.role == USER_ROLE_TYPES.student || loggedInUser.role == USER_ROLE_TYPES.visitor) && (
											<NavItem>
												<NavLink tag={DOMNavLink} to="/my_scores">
													View Your Scores
												</NavLink>
											</NavItem>
										)}
									</Nav>
								</Collapse>
								<LoggedInUserNav user={loggedInUser}/>
							</React.Fragment>
						)}
					</LoggedInUserContext.Consumer>
				</Container>
			</Navbar>
		);
	}

}
