import React from "react";

import BaseComponent from "../components/BaseComponent";
import LoadingIndicator from "../components/LoadingIndicator";
import ajax from "../utils/ajax";
import {
	API_BASE_ADDRESS,
	CRITERIA_DESCRIPTIONS,
	EPT_LANGUAGES
} from "../utils/constants";

import "react-datetime/css/react-datetime.css";

export default class EditableActionUseEditPage extends BaseComponent{

	constructor(props) {
		super(props);
		this.state = {
			action: null,
			uses: null,
			new_criteria: "",
			new_language_id: "",
			new_weight: 0,
			pageTitle: "Loading..."
		};
		this.deleteEditableActionUse = this.deleteEditableActionUse.bind(this);
		this.addEditableActionUse = this.addEditableActionUse.bind(this);
	}

	initializeAction() {
		var newState;
		if (this.props.match.params.id) {
			ajax.get(API_BASE_ADDRESS + "editable_action/" + this.props.match.params.id)
				.then((data)=>{
					newState = {
						action: data.action,
						uses: data.uses,
						pageTitle: "Edit action usage",
					};
					this.addToState(newState);
				});
		}
	}

	componentDidMount() {
		this.initializeAction();
	}

	deleteEditableActionUse(id) {
		if (window.confirm("Are you sure you want to delete this action use?")) {
			ajax.delete(API_BASE_ADDRESS + "editable_action_use/delete/", {id: id})
				.then((data) => {
					if (data.deleted) {
						this.initializeAction();
					}
				});
		}
	}

	addEditableActionUse() {
		var add = true;

		// check for existing records
		if (this.state.action.action_type == "page") {
			this.state.uses.forEach((use) => {
				if (use.action_url == this.state.new_action_url) {
					alert("This URL already exists for this action.");
					add = false;
				}
			});
		} else {
			this.state.uses.forEach((use) => {
				if (use.criteria == this.state.new_criteria && use.language_id == this.state.new_language_id) {
					alert("This combination of criteria and language already exists for this piece of editable action.");
					add = false;
				}
			});
		}

		if (add) {
			// add the new use
			var new_action_use = {
				editable_action_id: this.state.action.id,
				criteria: this.state.new_criteria,
				language_id: this.state.new_language_id,
				weight: this.state.new_weight
			};
			ajax.post(API_BASE_ADDRESS + "editable_action_use/add", new_action_use)
				.then(()=>{
					// reset state for next addition
					var newState = {
						new_criteria: "",
						new_language_id: "",
						new_weight: 0,
					};
					this.addToState(newState);
					this.initializeAction();
				});
		}
	}

	render() {
		if (this.state.action == null) {
			return (
				<LoadingIndicator.Element/>
			);
		} else {
			//TODO display usage list in a table?
			return (
				<React.Fragment>
					<h1>{this.state.pageTitle}</h1>
					<div className="border bg-white p-3">
						<p><strong>Admin title:</strong> {this.state.action.admin_title}</p>
						<p><strong>Action text:</strong> {this.state.action.action_text}</p>
						<p><strong>Action link:</strong> <a href={this.state.action.action_url} target="_blank" rel="noopener noreferrer">{this.state.action.action_url}</a></p>
						{
							this.state.uses.length > 0 && (
								<div>
									<h2>Current Usage</h2>
									<ul>
										{
											this.state.uses.map((use) => {
												return (
													<li key={use.id} className="mb-1 row">
														<div className="col-4 border-bottom">
															{use.language} - <abbr title={CRITERIA_DESCRIPTIONS[use.criteria]}>{use.criteria}</abbr> - weighted {use.weight}
														</div>
														<div className="col">
															<button
																className="btn btn-sm btn-primary ml-1"
																onClick={() => this.deleteEditableActionUse(use.id)}
															>
																Delete
															</button>
														</div>
													</li>
												);
											})
										}
									</ul>
								</div>
							)
						}
						<h2>Add Usage</h2>
						<div>
							<div className="form-group">
								<label htmlFor="criteria">Criteria</label>
								<select
									className="form-control"
									id="criteria"
									value={this.state.new_criteria}
									onChange={(e)=> this.setState({new_criteria: e.target.value})}
								>
									<option value="">--choose</option>
									{
										Object.keys(CRITERIA_DESCRIPTIONS).map((criteria) => {
											return (
												<option key={criteria} name={criteria} value={criteria}>{criteria}: {CRITERIA_DESCRIPTIONS[criteria]}</option>
											);
										})
									}
								</select>
							</div>
							<div className="form-group">
								<label htmlFor="language">Language</label>
								<select
									className="form-control"
									id="language"
									value={this.state.new_language_id}
									onChange={(e)=> this.setState({new_language_id: e.target.value})}
								>
									<option value="">--choose</option>
									{
										Object.keys(EPT_LANGUAGES).map((language_id) => {
											return (
												<option key={language_id} name={language_id} value={language_id}>{EPT_LANGUAGES[language_id]}</option>
											);
										})
									}
								</select>
							</div>
							<div className="form-group">
								<label htmlFor="weight">Weight</label>
								<select
									className="form-control"
									id="weight"
									value={this.state.new_weight}
									onChange={(e)=> this.setState({new_weight: e.target.value})}
								>
									<option value="">--choose</option>
									{
										Object.keys([0,1,2,3,4,5]).map((weight) => {
											return (
												<option key={weight} name={weight} value={weight}>{weight}</option>
											);
										})
									}
								</select>
							</div>
							<button
								className="btn btn-primary"
								onClick={this.addEditableActionUse}
							>
								Add
							</button>
							<p><em>Note: use the &quot;weight&quot; value to control
								the order that this action appears in relation
								to other actions. A lower number means higher
								in the list.</em></p>
						</div>

						<a
							className="btn btn-outline-primary"
							role="button"
							href="/admin/content/index"
						>
							Done
						</a>
					</div>
				</React.Fragment>
			);
		}
	}
}
