import React from "react";
import {Route} from "react-router-dom";
import LoggedInUserContext from "./../contexts/LoggedInUserContext";

export default class AuthorizedRoute extends React.Component{

	checkForRole(loggedInUserRole, roles){
		if (!roles){
			return true;
		}

		var shouldRender = false;

		([].concat(roles)).map((role)=>{
			if (loggedInUserRole == role){
				shouldRender = true;
			}
		});

		return shouldRender;
	}

	render(){
		const {component: Component, roles, ...rest} = this.props;

		return (
			<LoggedInUserContext.Consumer>
				{loggedInUser=>(
					<Route
						{...rest}
						render={props => (
							loggedInUser ? (
								// User is logged in.
								this.checkForRole(loggedInUser.role, roles) ?
									(
										// User has the right role, so we can render the page
										<Component {...props} />
									) :
									(
										// User does not have the right role
										<h1>{"You aren't authorized to see this page."}</h1>
									)
							) : (() => {
								// User is not logged in, so redirect to Shibboleth login page
								window.location = "/signin?target=" + encodeURI(window.location.href);
								return false;
							}) ()

						)}
					/>
				)}
			</LoggedInUserContext.Consumer>
		);
	}
}
