import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter, selectFilter } from "react-bootstrap-table2-filter";
import truncate from "truncate-html";
import striptags from "striptags";

import BaseComponent from "./BaseComponent";
import ajax from "../utils/ajax";
import LoadingIndicator from "./LoadingIndicator";

import {
	API_BASE_ADDRESS,
	CRITERIA_DESCRIPTIONS,
	EDITABLE_CONTENT_TYPES
} from "../utils/constants";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

export default class EditableContentTableView extends BaseComponent {

	constructor() {
		super();

		this.state = {
			contents: null,
		};

		this.deleteContent = this.deleteContent.bind(this);
		this.getContents = this.getContents.bind(this);
		this.renderContent = this.renderContent.bind(this);
		this.renderContentType = this.renderContentType.bind(this);
		this.renderUsage = this.renderUsage.bind(this);
	}

	getContents() {
		ajax.get(API_BASE_ADDRESS + "editable_content/all")
			.then((data)=>{
				this.addToState(data);
			});
	}

	componentDidMount() {
		this.getContents();
	}

	deleteContent(content) {
		if (content.uses.length > 0) {
			alert("This content is in use, so it cannot be deleted.");
		} else if (window.confirm("Are you sure you want to delete this content?")) {
			ajax.delete(API_BASE_ADDRESS + "editable_content/delete", content)
				.then((data) => {
					if (data.deleted) {
						this.getContents();
					}
				});
		}
	}

	renderContent(row) {
		let html = truncate(striptags(row.html), 150);
		let showDeleteButton = true;
		if (row.uses.length > 0) {
			showDeleteButton = false;
		}

		return (
			<div>
				<div dangerouslySetInnerHTML={{__html: html}}></div>
				<a className="btn btn-sm btn-primary" href={"/admin/content/edit/" + row.id} title="edit this content">
				Edit
				</a>
				{ showDeleteButton ? (
					<button className="btn btn-sm btn-primary ml-1" onClick={() => {this.deleteContent(row)} } title="delete this content">
						Delete
					</button>
				) : ( null )
				}
			</div>
		);
	}

	renderContentType(row) {
		let content_type = row.content_type;
		let html = "<abbr title='" + EDITABLE_CONTENT_TYPES[content_type] + "'>" + content_type + "</abbr>";
		return (
			<div dangerouslySetInnerHTML={{__html: html}}></div>
		);
	}

	renderUsage(row) {
		let html = "";
		let title = "";

		if (row.content_type == "page") {
			// This is a page - just display the URLs.
			row.uses.map((use) => {
				if (html != "") {
					html += ", ";
				}
				html += "<a href='" + use.content_url + "' target='_blank'>" + use.content_url + "</a>";
			});
			title = "manage the URLs (web links) which display this page";
		} else {
			// Otherwise, group criteria by language to reduce displayed rows
			var uses_map = new Map();
			row.uses.map((use) => {
				let current = uses_map.get(use.language);
				if (!current) {
					current = "";
				} else {
					current += ", ";
				}
				current += "<abbr title='" + CRITERIA_DESCRIPTIONS[use.criteria] + "'>" + use.criteria + "</abbr>";
				uses_map.set(use.language, current);
			});

			uses_map.forEach((criteria, language) =>
				html += "<div key={language}>" + language + ": " + criteria + "</div>"
			);

			title = "manage the languages and criteria which display this content snippet";
		}

		return (
			<div>
				<div dangerouslySetInnerHTML={{__html: html}}></div>
				<a className="btn btn-sm btn-primary" href={"/admin/content/edit-use/" + row.id} title={title}>
					Manage
				</a>
			</div>
		);
	}

	generateColumns() {
		let columns = [
			{
				text: "Id",
				dataField: "id",
				hidden: true
			},
			{
				text: "Admin Title",
				dataField: "admin_title",
				filter: textFilter({
					placeholder: "filter by admin title",
					"aria-label": "filter by admin title"
				}),
				sort: true
			},
			{
				text: "Content Type",
				dataField: "content_type",
				formatter: (cellContent, row) => this.renderContentType(row),
				filter: selectFilter({
					options: EDITABLE_CONTENT_TYPES,
					placeholder: "filter by content type",
					"aria-label": "filter by content type"
				}),
				sort: true
			},
			{
				text: "Content Preview",
				dataField: "html",
				formatter: (cellContent, row) => this.renderContent(row),
				filter: textFilter({
					placeholder: "filter by content",
					"aria-label": "filter by content"
				})
			},
			{
				text: "Current Usage",
				dataField: "use",
				formatter: (cellContent, row) => this.renderUsage(row),
				filter: textFilter({
					placeholder: "filter by usage",
					"aria-label": "filter by usage"
				}),
				filterValue: (cell, row) => {
					let filterText = "";
					if (row.content_type == "page") {
						row.uses.map((use) => {
							filterText += " " + use.content_url;
						});
					} else {
						row.uses.map((use) => {
							filterText += " " + use.language + " " + use.criteria;
						});
					}
					return filterText;
				}
			}
		];

		return columns;
	}

	render() {
		const buttons =
			<div className="mb-3">
				<a className="btn btn-sm btn-primary" href={"/admin/content/add"}>
					Create New
				</a>
				<a className="btn btn-sm btn-primary ml-1" href={"/admin/content/missing"}>
					Show Missing
				</a>
			</div>;

		if (this.state.contents == null) {
			return (
				<div>
					{buttons}
					<LoadingIndicator.Element/>
				</div>
			);
		} else if (this.state.contents.length === 0) {
			return (
				<div>
					<p>No content is currently available.</p>
					{buttons}
				</div>
			);
		} else {
			return (
				<div>
					{buttons}
					<BootstrapTable
						boostrap4
						keyField="id"
						data={ this.state.contents }
						columns={ this.generateColumns() }
						bordered={ false }
						filter={ filterFactory() }
						defaultSorted={ [{dataField: "admin_title", order: "asc"}] }
					/>
				</div>
			);
		}
	}
}
