import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {	selectFilter } from "react-bootstrap-table2-filter";

import BaseComponent from "./BaseComponent";
import ajax from "../utils/ajax";
import LoadingIndicator from "./LoadingIndicator";

import {
	API_BASE_ADDRESS,
	CRITERIA_DESCRIPTIONS,
	EDITABLE_CONTENT_TYPES,
	EPT_LANGUAGES
} from "../utils/constants";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

export default class EditableContentMissingTableView extends BaseComponent {

	constructor() {
		super();

		this.state = {
			missing_content_uses: null,
		};

		this.renderContentType = this.renderContentType.bind(this);
		this.renderCriteria = this.renderCriteria.bind(this);
		this.getMissingContentUses = this.getMissingContentUses.bind(this);
	}

	getMissingContentUses() {
		ajax.get(API_BASE_ADDRESS + "editable_content_use/missing")
			.then((data)=>{
				this.addToState(data);
			});
	}

	componentDidMount() {
		this.getMissingContentUses();
	}

	renderContentType(use) {
		let html = "<abbr title='" + EDITABLE_CONTENT_TYPES[use.content_type] + "'>" + use.content_type + "</abbr>";
		return (
			<div dangerouslySetInnerHTML={{__html: html}}></div>
		);
	}

	renderCriteria(use) {
		let html = "<abbr title='" + CRITERIA_DESCRIPTIONS[use.criteria] + "'>" + use.criteria + "</abbr>";
		return (
			<div dangerouslySetInnerHTML={{__html: html}}></div>
		);
	}

	generateColumns() {
		let language_options = new Object();
		Object.keys(EPT_LANGUAGES).map((key) => {
			let language = EPT_LANGUAGES[key];
			language_options[language] = language;
		});

		let criteria_options = {};
		Object.keys(CRITERIA_DESCRIPTIONS).map((key) => {
			criteria_options[key] = key + ": " + CRITERIA_DESCRIPTIONS[key];
		});

		let columns = [
			{
				text: "Id",
				dataField: "id",
				hidden: true
			},
			{
				text: "Language",
				dataField: "language_name",
				filter: selectFilter({
					options: language_options,
					placeholder: "filter by language",
					"aria-label": "filter by language"
				}),
				sort: true
			},
			{
				text: "Criteria",
				dataField: "criteria",
				formatter: (cellContent, row) => this.renderCriteria(row),
				filter: selectFilter({
					options: criteria_options,
					placeholder: "filter by criteria",
					"aria-label": "filter by criteria"
				}),
				sort: true
			},
			{
				text: "Content Type",
				dataField: "content_type",
				formatter: (cellContent, row) => this.renderContentType(row),
				filter: selectFilter({
					options: EDITABLE_CONTENT_TYPES,
					placeholder: "filter by content type",
					"aria-label": "filter by content type"
				}),
				sort: true
			}
		];

		return columns;
	}

	render() {
		if (this.state.missing_content_uses == null) {
			return (
				<LoadingIndicator.Element/>
			);
		} else if (this.state.missing_content_uses.length === 0) {
			return (
				<div>
					<p>No content is missing!</p>
				</div>
			);
		} else {
			return (
				<div>
					<BootstrapTable
						bootstrap4
						keyField="id"
						data={ this.state.missing_content_uses }
						columns={ this.generateColumns() }
						bordered={ false }
						filter={ filterFactory() }
						defaultSorted={ [{dataField: "language_name", order: "asc"}] }
					/>
				</div>
			);
		}
	}
}
