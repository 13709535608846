import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from "react-router-dom";
import AuthorizedRoute from "../components/AuthorizedRoute";
import {USER_ROLE_TYPES} from "./../utils/constants";
import NavigationBarForAdmin from "../components/NavigationBarForAdmin";
import NavigationBarForHomePage from "../components/NavigationBarForHomePage";
import HomePage from "./../pages/HomePage";

import EditableContentPage from "../pages/EditableContentPage";
import StudentSearchPage from "../pages/StudentSearchPage";
import StudentPage from "../pages/StudentPage";
import ReportsPage from "../pages/ReportsPage";
import AnnouncementsPage from "../pages/AnnouncementsPage";
import NotFoundPage from "../pages/NotFoundPage";
import LoggedInUserContext from "./../contexts/LoggedInUserContext";
import EnvContext from "./../contexts/EnvContext";
import FlashMessagesContainer from "./../components/FlashMessagesContainer";
import AssistantPage from "./../pages/AssistantPage";
import EditableContentAdminPage from "../pages/EditableContentAdminPage";
import EditableContentMissingPage from "../pages/EditableContentMissingPage";
import EditableContentEditPage from "../pages/EditableContentEditPage";
import EditableActionEditPage from "../pages/EditableActionEditPage";
import EditableActionUseEditPage from "../pages/EditableActionUseEditPage";
import EditableContentUseEditPage from "../pages/EditableContentUseEditPage";
import ContactUsPage from "../pages/ContactUsPage";
import FrequentlyAskedQuestionsPage from "../pages/FrequentlyAskedQuestionsPage";
import FrequentlyAskedQuestionsEditPage from "../pages/FrequentlyAskedQuestionsEditPage";

export default class App extends React.Component {
  componentDidMount() {
    // remove our spinner once the app is loaded
    document.getElementById("app-loading-spinner")?.remove();
  }

	render(){
		const loggedInUser = this.props.loggedInUser;

		return(
			<LoggedInUserContext.Provider value={loggedInUser}>
				<EnvContext.Provider value={this.props.env}>
					<Router>
						<div>
							<div id="main-nav">
								<Switch>
									<Route path="/admin" component={NavigationBarForAdmin}/>
									<Route path="/" component={NavigationBarForHomePage}/>
								</Switch>
							</div>
							<main className="content-container container" id="main-content">
								<FlashMessagesContainer/>
								<Switch>
									<Redirect from="/ept/faq" to="/faq?tab=1"/>
									<Redirect from="/home/faq" to="/faq"/>
									<Redirect from="/home/eligibility" to="/ept/eligibility"/>

									<Route exact path="/" component={()=> <Redirect to="/home"/>}/>
									<Route exact path="/home" component={HomePage}/>
									<Route path="/info/contact/:location" component={ContactUsPage}/>
									<Route path="/info/contact" component={ContactUsPage}/>
									<Route path="/ept" component={EditableContentPage}/>
									<Route path="/info" component={EditableContentPage}/>
									<Route path="/faq" component={FrequentlyAskedQuestionsPage}/>

									<AuthorizedRoute
										path="/my_scores"
										component={(props)=>(
											<StudentPage {...props} uofm_id={loggedInUser.emplid} />
										)}
										roles={[
											USER_ROLE_TYPES.student,
											USER_ROLE_TYPES.admin,
											USER_ROLE_TYPES.advisor,
											USER_ROLE_TYPES.visitor
										]}
									/>

									<AuthorizedRoute
										path="/admin/students/:uofm_id"
										component={StudentPage}
										roles={[
											USER_ROLE_TYPES.admin,
											USER_ROLE_TYPES.advisor
										]}
									/>

									<AuthorizedRoute
										exact
										path="/admin/students"
										component={StudentSearchPage}
										roles={[
											USER_ROLE_TYPES.admin,
											USER_ROLE_TYPES.advisor
										]}
									/>

									<AuthorizedRoute
										path="/admin/reports"
										component={ReportsPage}
										roles={[
											USER_ROLE_TYPES.admin,
											USER_ROLE_TYPES.advisor]}
									/>

									<AuthorizedRoute
										path="/admin/announcements"
										component={AnnouncementsPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<AuthorizedRoute
										path="/admin/content/index"
										component={EditableContentAdminPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<AuthorizedRoute
										path="/admin/content/missing"
										component={EditableContentMissingPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<AuthorizedRoute
										path="/admin/content/edit-use/:id"
										component={EditableContentUseEditPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<AuthorizedRoute
										path="/admin/content/edit/:id"
										component={EditableContentEditPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<AuthorizedRoute
										path="/admin/content/add"
										component={EditableContentEditPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<AuthorizedRoute
										path="/admin/action/edit-use/:id"
										component={EditableActionUseEditPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<AuthorizedRoute
										path="/admin/action/edit/:id"
										component={EditableActionEditPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<AuthorizedRoute
										path="/admin/action/add"
										component={EditableActionEditPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<AuthorizedRoute
										path="/admin/faq"
										component={FrequentlyAskedQuestionsEditPage}
										roles={USER_ROLE_TYPES.admin}
									/>

									<Route path="/assistant" component={AssistantPage}/>
									<Route component={NotFoundPage} />
								</Switch>
							</main>
						</div>
					</Router>
				</EnvContext.Provider>
			</LoggedInUserContext.Provider>
		);

	}

}
