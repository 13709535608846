import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import capitalize from "lodash/capitalize";
import BaseComponent from "./../BaseComponent";
import ajax from "./../../utils/ajax.js";
import LoggedInUserContext from "./../../contexts/LoggedInUserContext";
import {API_BASE_ADDRESS} from "../../utils/constants";
import { ArrowUpIcon } from "../Icons";

class PriorityScoringModal extends BaseComponent{
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			customMessage: ""
		};
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal
		});
	};

	sendPriorityRequestEmail = ()=>{
		let exam = this.props.exam;
		let student = this.props.student;
		let customMessage = this.state.customMessage;
		let advisorName = this.props.loggedInUser.display_name;
		let advisorEmail = this.props.loggedInUser.user_id + "@umn.edu";

		let bodyText = advisorName + " requests priority scoring.\n\n"
			+ "Exam: " + exam.language.name + " " + exam.language.exam_type.name + "\n"
			+ "Student Name: " + student.first_name + " " + capitalize(student.last_name) + "\n"
			+ "Internet ID: " + student.x500 + "\n"
			+ "Student ID: " + student.uofm_id + "\n";

		if (customMessage.length > 0) {
			bodyText += "\nAdditional information (provided by " + advisorName + "):\n" + customMessage + "\n";
		}

		const params = {
			from: advisorEmail,
			body: bodyText,
			subject: "PRIORITY SCORING REQUEST"
		};

		ajax.post(API_BASE_ADDRESS + "send_umn_email", params).then(()=>{
			this.toggle();
		});
	};

	render() {
		const exam = this.props.exam;
		const student = this.props.student;
		const studentName = student.first_name + " " + capitalize(student.last_name);
		const customMessage = this.state.customMessage;

		return(
			<React.Fragment>
				<button
					className="btn btn-sm btn-outline-primary wrap-text"
					type="button"
					onClick={this.toggle}
				>
					Request Priority Scoring
				</button>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>
						Request Priority Scoring for <strong>{studentName}</strong>
					</ModalHeader>
					<ModalBody>
						<form id="priority-scoring-form">
							<div className="row mb-3">
								<div className="col-sm-auto text-center" aria-hidden="true">
									<ArrowUpIcon className="text-primary"/>
								</div>
								<div className="col text-primary">
									Email the CLA Language Testing Program Administrators to request priority scoring for <strong>{studentName}&apos;s
									</strong> {exam.language.name} {exam.language.exam_type.name} writing section.
								</div>
							</div>
							<div className="form-group">
								<label htmlFor="customMessage">
									Custom message (optional)
								</label>
								<textarea
									id="customMessage"
									className="form-control"
									aria-describedby="customMessageHelp"
									value={customMessage}
									onChange={(e)=> this.setState({customMessage: e.target.value})}
								/>
								<small id="customMessageHelp" className="form-text text-muted">
									Your name and email address will be included in the message automatically.
								</small>
							</div>
						</form>
					</ModalBody>
					<ModalFooter>
						<button
							className="btn btn-primary ml-sm-2"
							type="button"
							key={0}
							onClick={this.sendPriorityRequestEmail}
						>
							Send Request
						</button>
						<button
							className="btn btn-outline-secondary ml-sm-2"
							type="button"
							key={1}
							onClick={this.toggle}
						>
							Cancel
						</button>
					</ModalFooter>
				</Modal>
			</React.Fragment>
		);
	}
}

export default props => (
	<LoggedInUserContext.Consumer>
		{loggedInUser => (
			<PriorityScoringModal {...props} loggedInUser={loggedInUser}/>
		)}
	</LoggedInUserContext.Consumer>
);

