import React from "react";
import BaseComponent from "./BaseComponent";
import BootstrapTable from "react-bootstrap-table-next";
import {EXAM_SECTIONS, GRADE_TYPES} from "./../utils/constants";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

export default class ReportTableView extends BaseComponent {

	getCellForResult(result){

		if (!result){
			return "-";
		}

		const numberScore = parseInt(result.score);

		return (
			<div>
				{
					EXAM_SECTIONS[result.type.toLowerCase()] != EXAM_SECTIONS.writing //make sure it's not the writing section
					&& (numberScore || numberScore === 0) //does it have an actual value
					&& (result.score + " - ")
				}
				{GRADE_TYPES[result.status]}
				<div className="small text-muted">
					{result.examdate}
				</div>
			</div>
		);
	}
	generateColumns(){
		const columns = [
			{
				text: "Student ID",
				dataField: "student_info.student_id",
				hidden: true
			},
			{
				text: "Student",
				dataField: "student_info",
				formatter: (cellContent, row) => {
					const href = `/admin/students/${row.student_info.student_id}`;

					return (
						<div>
							<div>
								<a
									href={href}
									target="_blank"
									rel="noopener noreferrer"
								>
									{row.student_info.fullname}
								</a>
							</div>
							<div>
								<ul className="list-inline small text-muted">
									<li className="list-inline-item with-separator"><span className="sr-only">Internet ID: </span>{row.student_info.x500}</li>
									<li className="list-inline-item with-separator"><span className="sr-only">Student ID: </span>{row.student_info.student_id}</li>
								</ul>
							</div>
						</div>
					);
				}
			},
			{
				text: "Exam",
				dataField: "exam_info",
				formatter: (cellContent, row) => {
					return(
						<div>
							{row.exam_info.name} {row.exam_info.exam_type}
						</div>
					);
				}
			},
			{
				text: "Reading",
				dataField: "reading",
				formatter: (cellContent, row) => this.getCellForResult(row.reading)
			},
			{
				text: "Listening",
				dataField: "listening",
				formatter: (cellContent, row) => this.getCellForResult(row.listening)
			},
			{
				text: "Writing",
				dataField: "writing",
				formatter: (cellContent, row) => this.getCellForResult(row.writing)
			},
			{
				text: "Overall Status",
				dataField:"date_taken",
				formatter: (cellContent, row) => GRADE_TYPES[row.overall]
			}
		];

		return columns;
	}
	render() {
		if (!this.props.data){
			return (
				<div> no results </div>
			);
		}

		return (
			<BootstrapTable
				id="report-table"
				boostrap4
				keyField="student_info.student_id"
				data={ this.props.data }
				columns={ this.generateColumns() }
				bordered={ false }
				classes="mt-3 bg-white"
			/>
		);
	}
}
