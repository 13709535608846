import React from "react";
import { Link } from "react-router-dom";
import BaseComponent from "./../components/BaseComponent";
import EditableContentMissingTableView from "../components/EditableContentMissingTableView";

export default class EditableContentMissingPage extends BaseComponent{

	render(){
		return (
			<React.Fragment>

				<h1>Missing Editable Content</h1>

				<div className="border bg-white p-3">
					<p>
						This page provides a list of content type, language and
						criteria combinations for which no content has been
						assigned yet.
					</p>
					<p>
						You can assign content by clicking on the
						&quot;manage&quot; button next to the appropriate
						content on the main <Link to={"/admin/content/index"}>
						Editable Content admin page</Link>.
					</p>

					<EditableContentMissingTableView />
				</div>
			</React.Fragment>
		);
	}
}
