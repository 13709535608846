import React from "react";
import ajax from "./../utils/ajax";
import BaseComponent from "./../components/BaseComponent.js";
import LoadingIndicator from "./../components/LoadingIndicator";
import NotFoundPage from "./../pages/NotFoundPage";
import {API_BASE_ADDRESS} from "../utils/constants";

export default class EditableContentPage extends BaseComponent{

	state = { };

	fetchContent(){
		return ajax.get(API_BASE_ADDRESS + "editable_content/page", {content_url: this.props.contentUrl || this.props.location.pathname})
			.then(contents=>{
				this.addToState({contents});
			});
	}
	componentDidMount(){
		this.fetchContent();
	}
	render(){
		if (this.state.contents === undefined) {
			return (
				<LoadingIndicator.Element type={LoadingIndicator.TYPES.fill} delay={1000}/>
			);
		} else if (this.state.contents === null || this.state.contents.size == 0) {
			return (
				<NotFoundPage/>
			);
		}

		return(
			<React.Fragment>
				{
					this.state.contents.map((content, i) =>
						<span
							key={ i }
							dangerouslySetInnerHTML={{__html: content.html}}>
						</span>
					)
				}
			</React.Fragment>
		);
	}

}
