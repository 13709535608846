import React from "react";
import BaseComponent from "./BaseComponent";

var LoadingIndicator = {};
export default LoadingIndicator;

LoadingIndicator.Element = class extends BaseComponent {

	state = {
		onDelay:false
	}

	componentDidMount(){
		if (this.props.delay){
			this.addToState({onDelay:true});
		}

		this.timeout = setTimeout( ()=>{
			this.addToState({onDelay:false});
		}, this.props.delay);
	}
	componentWillUnmount(){
		if (this.timeout){
			clearTimeout(this.timeout);
		}
	}
	render(){
		if (this.state.onDelay){
			return (<div></div>);
		}

		var className = "loading-indicator "
										+ (LoadingIndicator.TYPES[this.props.type] || "")
										+ " "
										+ (LoadingIndicator.SIZES[this.props.size] ||  "medium");

		return (
			<div className={className}>
			</div>
		);
	}

};

LoadingIndicator.Wrapper = class extends BaseComponent {

	render(){
		var className = "loading-indicator loading-indicator-dimmer "+ (LoadingIndicator.TYPES[this.props.type] || "");
		var show = this.props.show;

		return(
			<div className={show ? "loading-indicator-wrapper" : ""}>
				<div className={show ? className : ""}>
				</div>
				{this.props.children}
			</div>
		);

	}

};

LoadingIndicator.SIZES = {
	small:"small",
	medium:"medium",
	large:"large"
};

LoadingIndicator.TYPES = {
	normal:"normal",
	fill:"fill"
};
