import React from "react";
import BaseComponent from "./../components/BaseComponent";
import AnnouncementTableView from "./../components/AnnouncementTableView";

export default class AnnouncementsPage extends BaseComponent{

	render(){
		return (
			<React.Fragment>
				<h1>Announcements</h1>
				<div className="border bg-white p-3">
					<AnnouncementTableView />
				</div>
			</React.Fragment>
		);
	}
}
