import React from "react";
import BaseComponent from "./BaseComponent";
import {CRITERIA_DESCRIPTIONS} from "../utils/constants";

export default class EditableContentUse extends BaseComponent{
	render(){
		const content_type = this.props.content_type;
		const criteria = this.props.criteria;
		const language = this.props.language;
		const content_url = this.props.content_url;
		const className = this.props.className;

		if (content_type == "page") {
			return (
				<div className={className}><a href={content_url} target="_blank">{content_url}</a></div>
			);
		} else {
			return (
				<div className={className}>
					{language} - <abbr title={CRITERIA_DESCRIPTIONS[criteria]}>{criteria}</abbr>
				</div>
			);
		}
	}
}
