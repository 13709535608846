import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { NavLink as DOMNavLink } from "react-router-dom";
import queryString from "query-string";

export default class TabManager extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			activeTab: "0"
		};
	}

	componentDidMount() {
		if (this.props.tabs) {
			let activeTab = "";
			if (this.props.queryString !== "") {
				activeTab = queryString.parse(this.props.queryString).tab;
			}
			if (activeTab == "") {
				// Use first tab as default active tab.
				activeTab = this.props.tabs[0].customIndex || "0";
			}
			this.setState({activeTab: activeTab});
		}
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	render(){
		const tabs = this.props.tabs;
		if (tabs.length == 1) {
			return (
				<div className="border bg-white p-3">
					<h2>{tabs[0].title}</h2>
					{tabs[0].content}
				</div>
			);
		} else {
			let tabTitleElements = [];
			let tabContentElements = [];

			tabs.map((currentTab, index) => {
				let tabId = currentTab.customIndex || index.toString();
				let navLinkClassName = "";
				let activeTab = false;
				if (this.state.activeTab === tabId) {
					navLinkClassName += " active";
					activeTab = true;
				}
				if (this.props.displayType == "vertical") {
					tabTitleElements[index] = (
						<NavItem key={"nav_item_" + index} className="border rounded">
							<NavLink
								className={navLinkClassName}
								tag={DOMNavLink}
								onClick={() => {
									this.toggle(tabId);
								}}
								to={this.props.baseURL + "?tab=" + tabId}
							>
								{currentTab.title}
							</NavLink>
						</NavItem>
					);

					tabContentElements[index] = (
						<TabPane tabId={tabId} key={"tab_pane_" + index}>
							<h2 className="text-muted"><small>{currentTab.title}</small></h2>
							{currentTab.content}
						</TabPane>
					);
				} else {
					if (activeTab) {
						navLinkClassName += " bg-white";
					}
					tabTitleElements[index] = (
						<NavItem key={"nav_item_" + index} className="">
							<NavLink
								className={navLinkClassName}
								tag={DOMNavLink}
								onClick={() => {
									this.toggle(tabId);
								}}
								to={this.props.baseURL + "?tab=" + tabId}
							>
								<h2>{currentTab.title}</h2>
							</NavLink>
						</NavItem>
					);

					tabContentElements[index] = (
						<TabPane tabId={tabId} key={"tab_pane_" + index}>
							{currentTab.content}
						</TabPane>
					);
				}
			});

			if (this.props.displayType == "vertical") {
				let contentClassName = "";
				if (this.props.paneStyle == "plain") {
					contentClassName = "col";
				} else {
					contentClassName = "col border bg-white p-3";
				}
				return (
					<div className="row no-gutters">
						<div className="col-sm-12 col-md-auto mb-2 mb-md-0 mr-md-2">
							<Nav vertical pills>
								{tabTitleElements}
							</Nav>
						</div>
						<div className={contentClassName}>
							<TabContent activeTab={this.state.activeTab}>
								{tabContentElements}
							</TabContent>
						</div>
					</div>
				);
			} else {
				return (
					<React.Fragment>
						<Nav tabs>
							{tabTitleElements}
						</Nav>
						<div
							className="p-4 bg-white border-left border-right border-bottom">
							<TabContent activeTab={this.state.activeTab}>
								{tabContentElements}
							</TabContent>
						</div>
					</React.Fragment>
				);
			}
		}
	}
}

TabManager.defaultProps = {
	displayType: "horizontal"
};