import React from "react";
import SectionScorePanel from "./SectionScorePanel";
import {GRADE_TYPES, EXAM_SECTIONS} from "./../utils/constants";
import ReadingPlusListeningView from "./ReadingPlusListeningView";
import invert from "lodash/invert";

export default class SectionScorePanelList extends React.Component{

	state = {};

	onClickExamMini = (index)=>{
		this.setState({selected:index});
	};
	getScoresFromExamResults(examResults){
		var examScores = {};
		const {reading, listening, writing, reading_plus_listening} = examResults;

		examScores[EXAM_SECTIONS.reading] = reading;

		if (reading_plus_listening.continued_to_listening){
			examScores[EXAM_SECTIONS.listening] = listening;
			examScores[EXAM_SECTIONS.reading_plus_listening] = reading_plus_listening;

			if (reading_plus_listening.status !== invert(GRADE_TYPES).Fail){
				examScores[EXAM_SECTIONS.writing] = writing;
			}
		}

		return examScores;

	}
	render(){
		const exam = this.props.exam;
		const scores = this.getScoresFromExamResults(exam.results);
		const mostRecentReadingPlusListening = scores[EXAM_SECTIONS.reading_plus_listening];
		const mostRecentWriting = scores[EXAM_SECTIONS.writing];
		const isPendingWritingOnly = mostRecentReadingPlusListening //must be present
															&& GRADE_TYPES[mostRecentReadingPlusListening.status] !== GRADE_TYPES.F //must not be fail
															&& GRADE_TYPES[mostRecentReadingPlusListening.status] !== GRADE_TYPES.I //must not be a incomplete
															&& mostRecentWriting //must be present
															&& GRADE_TYPES[mostRecentWriting.status] === GRADE_TYPES.I; //must be incomplete

		return (
			<div className="mx-md-2">
				<div className="row">
					<div className="col-md-12">
						<div className="card-deck">
							{scores["Reading"] &&
								<SectionScorePanel
									key="Reading"
									name="Reading"
									score={scores["Reading"]}
									exam={exam}
									isPendingWritingOnly={isPendingWritingOnly}
									onAfterEdit={this.props.onAfterEdit}
								/>
							}
							{scores["Listening"] &&
								<SectionScorePanel
									key="Listening"
									name="Listening"
									score={scores["Listening"]}
									exam={exam}
									isPendingWritingOnly={isPendingWritingOnly}
									onAfterEdit={this.props.onAfterEdit}
								/>
							}
							{scores["Writing"] &&
								<SectionScorePanel
									key="Writing"
									name="Writing"
									score={scores["Writing"]}
									exam={exam}
									isPendingWritingOnly={isPendingWritingOnly}
									onAfterEdit={this.props.onAfterEdit}
								/>
							}
						</div>
					</div>
				</div>
				{mostRecentReadingPlusListening
				&& (mostRecentReadingPlusListening.status != "I")
				&& (this.props.showReadingAndListening !== false)
				&& (
					<div className="row">
						<div className="col-md-8">
							<div className="card-deck">
								<ReadingPlusListeningView
									score={mostRecentReadingPlusListening}
									exam={exam}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
