import React from "react";
import { Card, CardText, CardBody, CardHeader, CardTitle, UncontrolledCollapse } from "reactstrap";
import { PlusIcon, MinusIcon } from "./Icons";

export default class FAQ extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({open: !this.state.open});
	}

	render() {
		if (this.props.collapsible) {
			let headingID = "heading" + this.props.id;
			let bodyID = "body" + this.props.id;
			return (
				<Card>
					<CardHeader className="pl-2">
						<button
							className="btn btn-link accordion-header wrap-text text-left"
							type="button"
							onClick={this.toggle}
							id={headingID}
							aria-controls={bodyID}
							aria-expanded={this.state.open}
						>
							<h3>
								{ this.state.open ? <MinusIcon className="mr-2" /> : <PlusIcon className="mr-2" /> }
								{this.props.question}
							</h3>
						</button>
					</CardHeader>
					<UncontrolledCollapse
						id={bodyID}
						toggler={"#" + headingID}
						aria-label={this.props.question}
					>
						<CardBody>
							<CardText dangerouslySetInnerHTML={{__html: this.props.answer}} />
						</CardBody>
					</UncontrolledCollapse>
				</Card>
			);
		} else {
			return (
				<Card>
					<CardBody>
						<CardTitle>{this.props.question}</CardTitle>
						<CardText dangerouslySetInnerHTML={{__html: this.props.answer}} />
					</CardBody>
				</Card>
			);
		}
	}
}