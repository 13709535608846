import React from "react";
import DateTime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";

export default class DateRangePicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			date1: null,
			date2: null,
			type: props.pickerType
		};
	}

	selectedDate1 = date => {
		this.setState({date1: date}, this.datetime.openCalendar);
		this.props.onChangeStart(moment(date.valueOf()));
	};
	selectedDate2 = date => {
		this.setState({date2: date});
		this.props.onChangeEnd(moment(date.valueOf()));
	};

	render() {
		var now = moment();
		var defaultValidStartDate = current => {
			var ok = true;
			ok = current.isBefore(now);
			return ok;
		};
		var defaultValidEndDate = current => {
			var ok = true;
			ok = current.isBefore(now);
			if (this.state.date1 && ok) {
				ok = current.isAfter(this.state.date1) || current.isSame(this.state.date1);
			}
			return ok;
		};
		let calendar2ViewDate = this.state.date1;
		let inputClassName = "form-control " + this.props.inputClassName;
		return (
			<div className="form-row">
				<div className="col">
					<DateTime
						id="dateRange"
						key={"start_" + this.props.reset}
						isValidDate={this.props.validStartDate ? this.props.validStartDate : defaultValidStartDate}
						closeOnSelect={true}
						onChange={this.selectedDate1}
						inputProps={{id: "date1", className: inputClassName, placeholder: "Start", disabled: this.props.disabled, "aria-label": "Start Date"}}
						timeFormat={false}
					/>
				</div>
				<div className="col">
					<DateTime
						id="dateRange"
						key={"end_" + this.props.reset}
						isValidDate={this.props.validEndDate ? this.props.validEndDate : defaultValidEndDate}
						closeOnSelect={true}
						onChange={this.selectedDate2}
						inputProps={{id: "date2", className: inputClassName, placeholder: "End", disabled: this.props.disabled, "aria-label": "End Date"}}
						timeFormat={false}
						viewDate={calendar2ViewDate}
						ref={ref => {
							this.datetime = ref;
						}}
					/>
				</div>
			</div>
		);
	}
}
