import React from "react";
import AssistantStartPanel from "./../components/assistant/AssistantStartPanel";
import AssistantChooseLanguagePanel from "./../components/assistant/AssistantChooseLanguagePanel";
import AssistantControllerPanel from "./../components/assistant/AssistantControllerPanel";
import {Route, Switch} from "react-router-dom";
import AuthorizedRoute from "./../components/AuthorizedRoute";

export default class AssistantWrapper extends React.Component{
	render(){
		const {match} = this.props;

		return (
			<div className="card text-center">
				<Switch>
					<AuthorizedRoute
						path={`${match.url}/language`}
						component={AssistantChooseLanguagePanel}/>
					<AuthorizedRoute
						path={`${match.url}/run/:lang`}
						component={AssistantControllerPanel}/>
					<Route
						path={`${match.url}/`}
						component={AssistantStartPanel}/>
				</Switch>
			</div>
		);
	}
}
