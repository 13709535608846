import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ajax from "./../../utils/ajax";
import {API_BASE_ADDRESS} from "./../../utils/constants";
import capitalize from "lodash/capitalize";
import { PencilIcon } from "../Icons";

export default class EditScoreModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			scoreValue: null
		};
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal
		});
	};

	updateScore = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let form = document.getElementById("edit-score-form");
		if (form.checkValidity() === false) {
			this.setState({form_class: "was-validated"});
		} else {
			const params = {score: this.state.scoreValue, student_score_id: this.props.score.student_score_id};

			ajax.put(API_BASE_ADDRESS + "/student_scores/update", params)
				.then((success)=>{
					if (success){
						this.props.onAfterEdit();
						this.toggle();
					}
				});
		}
	};

	componentDidMount() {
		this.setState({scoreValue:this.props.score.score});
	}

	render() {
		const scoreValue = this.state.scoreValue;
		const maxScoreValue = this.props.score.max;
		const scoreType = capitalize(this.props.score.type);
		const student = this.props.student;
		const studentName = student.first_name + " " + capitalize(student.last_name);
		let allowedScoreValues = [];
		for (var i = 0; i<= maxScoreValue; i++) {
			allowedScoreValues.push(i);
		}
		return(
			<React.Fragment>
				<button
					className="btn btn-outline-primary btn-sm"
					type="button"
					onClick={this.toggle}>
					Edit <PencilIcon />
				</button>
				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>
						Edit <strong>{scoreType}</strong> Score for <strong>{studentName}</strong>
					</ModalHeader>
					<ModalBody>
						<form id="edit-score-form" className={this.state.form_class} noValidate>
							<div className="form-group">
								<label htmlFor="newScore">
									{scoreType + " score"}
								</label>
								<select
									id="newScore"
									className="form-control"
									value={scoreValue}
									aria-describedby="newScoreHelp"
									onChange={(e)=> this.setState({scoreValue: e.target.value})}
									required
								>
									{
										allowedScoreValues.map((allowedScoreValue) => {
											return (
												<option
													key={allowedScoreValue}
													id={allowedScoreValue}
													value={allowedScoreValue}>
													{allowedScoreValue}
												</option>
											);
										})
									}
								</select>
								<div className="invalid-feedback">
									Please select a number between 0 and {maxScoreValue}
								</div>
							</div>
						</form>
					</ModalBody>
					<ModalFooter>
						<button
							className="btn btn-primary ml-sm-2"
							type="button"
							key={"save_" + student.student_score_id}
							onClick={this.updateScore}
						>
							Update Score
						</button>
						<button
							className="btn btn-outline-secondary ml-sm-2"
							type="button"
							key={"cancel_" + student.student_score_id}
							onClick={this.toggle}
						>
							Cancel
						</button>
					</ModalFooter>
				</Modal>
			</React.Fragment>
		);
	}
}

EditScoreModal.defaultProps = {
	onAfterEdit: () => {
		return;
	}
};