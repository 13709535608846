import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import BaseComponent from "./BaseComponent";
import EditFAQModal from "./modals/EditFAQModal";
import FAQ from "./FAQ";

import ajax from "../utils/ajax";
import {API_BASE_ADDRESS, FAQ_CATEGORIES} from "../utils/constants";
import LoadingIndicator from "./LoadingIndicator";

export default class FAQTableView extends BaseComponent {
	constructor() {
		super();

		this.state = {
			contents: null,
		};

		this.getFAQs = this.getFAQs.bind(this);
		this.renderButtons = this.renderButtons.bind(this);
		this.renderFAQ = this.renderFAQ.bind(this);
	}

	getFAQs() {
		ajax.get(API_BASE_ADDRESS + "faqs/all")
			.then((data)=>{
				this.addToState(data);
			});
	}

	componentDidMount() {
		this.getFAQs();
	}

	renderFAQ(faq) {
		return (
			<FAQ collapsible={true} key={faq.id} index={faq.id} {...faq} />
		);
	}
	renderButtons(faq) {
		return (
			<React.Fragment>
				<EditFAQModal onSaveFAQ={this.getFAQs} faq={faq} />
				<button className="btn btn-primary btn-sm ml-sm-2" type="button" onClick={() => {
					if (window.confirm("Are you sure you want to delete this FAQ?"))
						this.deleteFaq(faq.id);
				}}>
				Delete
				</button>
			</React.Fragment>
		);
	}

	deleteFaq(id) {
		ajax.delete(API_BASE_ADDRESS + "faqs/delete", {id})
			.then((data) => {
				if (data.deleted) {
					this.getFAQs();
				}
			});
	}

	generateColumns() {
		const columns = [
			{
				dataField: "id",
				text: "Id",
				hidden: true
			},
			{
				dataField: "faqs",
				isDummyField: true,
				text: "Content",
				formatter: (cellContent, row) => {
					return (
						this.renderFAQ(row)
					);
				}
			},
			{
				dataField: "category",
				text: "Category",
				formatter: (cellContent, row) => {
					return FAQ_CATEGORIES[row.category];
				}
			},
			{
				dataField: "buttons",
				isDummyField: true,
				text: "Options",
				formatter: (cellContent, row) => {
					return (
						this.renderButtons(row)
					);
				}
			}
		];
		return columns;
	}

	render() {
		if (this.state.faqs == null) {
			return (
				<LoadingIndicator.Element/>
			);
		} else if (this.state.faqs.length === 0) {
			return (
				<div>
					<div className="alert alert-danger">No FAQs</div>
					<EditFAQModal onSaveFAQ={this.getFAQs} />
				</div>
			);
		} else {
			return (
				<div className="col-12">
					<div className="mb-3">
						<EditFAQModal onSaveFAQ={this.getFAQs} />
					</div>
					<BootstrapTable
						bootstrap4
						keyField="id"
						data={ this.state.faqs }
						columns={ this.generateColumns() }
						bordered={ false }
					/>
				</div>
			);
		}
	}
}