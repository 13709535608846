import React from "react";
import BaseComponent from "./../components/BaseComponent.js";
import {ENVIRONMENT_TYPES} from "../utils/constants";
import EnvContext from "./../contexts/EnvContext";

export default class HomePage extends BaseComponent{

	render(){
		return(
			<div className="row bg-white">
				<div className="col-12">
					<EnvContext.Consumer>
						{env => ( <span>
							{ env !== ENVIRONMENT_TYPES.production && (
								<div className="alert alert-danger text-center mt-3">
									IMPORTANT: This is the {env} build of LangTest 2.0. It is not using current production data.
								</div>
							) }
						</span> ) }
					</EnvContext.Consumer>

					<div className="row justify-content-center mt-5 mb-3">
						<div className="col">
							<h1 className="text-center">Welcome to the Language Testing Program</h1>
						</div>
					</div>
					<div className="row justify-content-center my-3">
						<div className="col-md-8">
							<p className="text-justify">The <a href="https://languagetesting.umn.edu">CLA Language Testing Program</a> administers the Entrance Proficiency Test (EPT), the <a href="https://languagetesting.umn.edu/lpe">Language Proficiency Exam (LPE)</a>, and the <a href="https://languagetesting.umn.edu/lpe-screening">LPE Screening Test</a> to students at the University of Minnesota - Twin Cities. We also deliver and interpret scores for these tests.</p>
							<p className="text-center"><a href="/ept/take" className="btn btn-primary" role="button">Take the EPT</a></p>
						</div>
					</div>
					<div className="row justify-content-center my-5">
						<div className="col-md-8">
							<h2 className="text-center">What is the EPT?</h2>
							<p className="">For French, German, Spanish: The EPT is a course placement test. To take a second-year course (1003-1004) at U of M in French, German, or Spanish, you must pass the Entrance Proficiency Test (EPT). The EPT consists of three portions: reading, listening, and writing. Each part lasts 50 minutes. All three portions of the EPT are taken online through this website, using a desktop or laptop. You may take the three portions consecutively or at different times. Your complete test results and course placement will be posted on this website within five business days of completing the EPT.</p>
							<p className="">For Standard Somali: The EPT for Standard Somali is now available online. The Somali EPT is a course placement test designed to assess your current proficiency in Standard Somali. Standard Somali is the variety of language used in Somalia for education, published writing, and other professional disciplines. This is a placement test designed to evaluate what you already know and place you into the best course for your abilities. The EPT consists of two portions: vocabulary/grammar and reading. You are given 50 minutes to complete the test. Both portions of the EPT are taken online through this website, using a desktop, laptop or smartphone. You must take all sections of the test in one sitting. Your complete test results and course placement will be posted on this website within five (5) to ten (10) business days of completing the EPT.</p>
							<p className="">For Hindi and Urdu: The Hindi and Urdu EPTs are course placement tests designed to assess your current proficiency in Hindi or Urdu. This is a placement test designed to evaluate what you already know and place you into the best course for your abilities. The EPT consists of vocabulary, grammar and reading. You are given 55 minutes to complete the test. This test can be taken online through this website, using a desktop, laptop or smartphone. Your complete test results and course placement will be posted on this website within five (5) to ten (10) business days of completing the EPT.</p>
							<div className="row justify-content-around">
								<div><a href="/ept/eligibility" className="btn btn-primary mb-3" role="button">Learn about eligibility</a></div>
								<div><a href="/faq?tab=0" className="btn btn-primary mb-3" role="button">Frequently Asked Questions</a></div>
							</div>
						</div>
						{/*
						<div className="col-sm">
							<h2 className="text-center">Do I Need the EPT? </h2>
							<p>Use the new <b>EPT</b> Assistant to determine what language test you need to take as a student at U of M. We’ll automatically look at your information and tell you what you should do next to fill your language requirement and further your language studies.</p>
							<div className="row justify-content-center"><a href="/assistant/start" className="btn btn-primary" role="button">Launch the Assistant</a></div>
						</div>
						*/}
					</div>
				</div>
			</div>
		);
	}

}
