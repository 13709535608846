import React from "react";

import ajax from "../utils/ajax";
import BaseComponent from "../components/BaseComponent";
import Announcement from "../components/Announcement";
import {API_BASE_ADDRESS} from "../utils/constants";

export default class ActiveAnnouncements extends BaseComponent {

	constructor(props) {
		super(props);

		this.state = {
			announcements: null
		};
	}

	getData() {
		ajax.get(API_BASE_ADDRESS + "announcements/active")
			.then((data)=>{
				this.addToState(data);
			});
	}

	componentDidMount() {
		this.getData();
	}

	render() {
		if (this.state.announcements != null && this.state.announcements.length > 0) {
			return (
				<React.Fragment>
					{
						this.state.announcements.map((announcement, i) =>
							<Announcement key={announcement.id} index={i} {...announcement}/>
						)
					}
				</React.Fragment>
			);
		} else {
			return null;
		}
	}

}