import React from "react";
import BaseComponent from "./../components/BaseComponent";
import FAQTableView from "./../components/FAQTableView";

export default class FrequentlyAskedQuestionsEditPage extends BaseComponent {

	render(){
		return (
			<React.Fragment>
				<h1>Edit Frequently Asked Questions</h1>
				<div className="border bg-white p-3">
					<FAQTableView />
				</div>
			</React.Fragment>
		);
	}
}