import React from "react";

export default class BaseComponent extends React.Component{

	//leaving this in for now. will make a ticket to remove this. 
	addToState (additionToState){
		this.setState(additionToState);
	}

}
