/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import React from "react"; // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom";
import App from "./containers/App.js";
import $ from "jquery";
import ReactModal from "react-modal";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import "./styles/application.scss";

$(document).ready(() => {
  const bootstrapScript = document.querySelector("#___bootstrap");
  var bootstrapObj = {};

  if (bootstrapScript) {
    bootstrapObj = JSON.parse(bootstrapScript.innerHTML);
  }

  bootstrapScript.remove();
  ReactModal.setAppElement("#app-wrapper");
  ReactDOM.render(
    <App
      loggedInUser={bootstrapObj.user}
      env={bootstrapObj.env}
      config={bootstrapObj.config}
    />,
    $("#app-wrapper")[0]
  );
});
