import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import BaseComponent from "./BaseComponent";
import ajax from "../utils/ajax";
import LoadingIndicator from "./LoadingIndicator";

import {
	API_BASE_ADDRESS,
	CRITERIA_DESCRIPTIONS
} from "../utils/constants";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

export default class EditableActionTableView extends BaseComponent {

	constructor() {
		super();

		this.state = {
			actions: null,
		};

		this.deleteAction = this.deleteAction.bind(this);
		this.getActions = this.getActions.bind(this);
		this.renderAction = this.renderAction.bind(this);
		this.renderUsage = this.renderUsage.bind(this);
	}

	getActions() {
		ajax.get(API_BASE_ADDRESS + "editable_action/all")
			.then((data)=>{
				this.addToState(data);
			});
	}

	componentDidMount() {
		this.getActions();
	}

	deleteAction(action) {
		if (action.uses.length > 0) {
			alert("This action is in use, so it cannot be deleted.");
		} else if (window.confirm("Are you sure you want to delete this action?")) {
			ajax.delete(API_BASE_ADDRESS + "editable_action/delete", action)
				.then((data) => {
					if (data.deleted) {
						this.getActions();
					}
				});
		}
	}

	renderAction(row) {
		let action_text = row.action_text;
		let action_url = row.action_url;
		let showDeleteButton = true;
		if (row.uses.length > 0) {
			showDeleteButton = false;
		}

		return (
			<div>
				<div><strong>Text:</strong> {action_text}</div>
				<div><strong>Link:</strong> <a href={action_url} target="_blank" rel="noopener noreferrer">{action_url}</a></div>
				<a className="btn btn-sm btn-primary" href={"/admin/action/edit/" + row.id} role="button">
				Edit
				</a>
				{ showDeleteButton ? (
					<button className="btn btn-sm btn-primary ml-1" onClick={() => {this.deleteAction(row)} }>
						Delete
					</button>
				) : ( null )
				}
			</div>
		);
	}

	renderUsage(action) {
		let html = "";

		let uses_map = new Map();
		action.uses.map((use) => {
			let current = uses_map.get(use.language);
			if (!current) {
				current = "";
			} else {
				current += ", ";
			}
			current += "<abbr title='" + CRITERIA_DESCRIPTIONS[use.criteria] + "'>" + use.criteria + "</abbr>";
			uses_map.set(use.language, current);
		});

		uses_map.forEach((criteria, language) =>
			html += "<div key={language}>" + language + ": " + criteria + "</div>"
		);

		return (
			<div>
				<div dangerouslySetInnerHTML={{__html: html}}></div>
				<a className="btn btn-sm btn-primary" href={"/admin/action/edit-use/" + action.id} role="button">
					Manage
				</a>
			</div>
		);
	}

	generateColumns() {
		let columns = [
			{
				text: "Id",
				dataField: "id",
				hidden: true
			},
			{
				text: "Admin Title",
				dataField: "admin_title",
				filter: textFilter({
					placeholder: "filter by admin title",
					"aria-label": "filter by admin title"
				}),
				sort: true
			},
			{
				text: "Action Preview",
				dataField: "uses",
				formatter: (cellContent, row) => this.renderAction(row),
				filter: textFilter({
					placeholder: "filter by action",
					"aria-label": "filter by action"
				}),
				filterValue: (cell, row) => {
					return row.action_text + " " + row.action_url;
				}
			},
			{
				text: "Current Usage",
				dataField: "use",
				formatter: (cellContent, row) => this.renderUsage(row),
				filter: textFilter({
					placeholder: "filter by usage",
					"aria-label": "filter by usage"
				}),
				filterValue: (cell, row) => {
					let filterText = "";
					row.uses.map((use) => {
						filterText += " " + use.language + " " + use.criteria;
					});
					return filterText;
				}
			}
		];

		return columns;
	}

	render() {
		const buttons =
			<div className="mb-3">
				<a className="btn btn-sm btn-primary" href={"/admin/action/add"} role="button">
					Create New
				</a>
			</div>;
		if (this.state.actions == null) {
			return (
				<div>
					{buttons}
					<LoadingIndicator.Element/>
				</div>
			);
		} else if (this.state.actions.length === 0) {
			return (
				<div>
					<p>No actions are currently available.</p>
					{buttons}
				</div>
			);
		} else {
			return (
				<div>
					{buttons}
					<BootstrapTable
						bootstrap4
						keyField="id"
						data={ this.state.actions }
						columns={ this.generateColumns() }
						bordered={ false }
						filter={ filterFactory() }
						defaultSorted={ [{dataField: "admin_title", order: "asc"}] }
					/>
				</div>
			);
		}
	}
}
